import getAlexandriaUrl from 'commons/getAlexandriaUrl';

const ALEXANDRIA_GRAPHQL_ENDPOINT = getAlexandriaUrl();
const headers = { 'Content-Type': 'application/json' };

const fetcherGraphQL = async query => {
  const options = {
    headers,
    method: 'POST',
    body: JSON.stringify(query),
  };

  const response = await fetch(ALEXANDRIA_GRAPHQL_ENDPOINT, options);

  if (!response.ok) {
    throw new Error(
      `HTTP Error: ${response?.status} - ${response?.statusText}`
    );
  }

  const responseJson = await response.json();

  if (responseJson.errors) {
    throw JSON.stringify(responseJson.errors);
  }

  return responseJson.data;
};

export default fetcherGraphQL;
