import React from 'react';
import PropTypes from 'prop-types';

import { colorGray } from 'styles/layout/colors';

const IconFilter = ({ color = colorGray, ...otherProps }) => (
  <svg height="14" viewBox="0 0 17 14" {...otherProps}>
    <g
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="square"
    >
      <g
        transform="translate(1.000000, 1.000000)"
        stroke={color}
        strokeWidth="1.5"
      >
        <path d="M0.784505208,1.899 L14.7845052,1.899" />
        <path d="M0.416666667,9.399 L14.4166667,9.399" />
        <path d="M11.4166667,0 L11.4166667,4" />
        <path d="M3.41666667,6.66666667 L3.41666667,11.6666667" />
      </g>
    </g>
  </svg>
);

IconFilter.propTypes = {
  color: PropTypes.string,
};

export default IconFilter;
