export const getFiltersInAvoFormat = filters => {
  const optionsArray = filters.map(filter =>
    filter.options
      ? filter?.options?.map(option => ({
          type: filter?.id,
          value: option?.id,
          isActive: option?.isActive,
        }))
      : {
          type: '',
          value: '',
          isActive: '',
        }
  );

  const createAvoArray = inputArray => {
    const outputArray = [];
    inputArray.forEach(filter => {
      if (filter?.length) {
        filter.forEach(option => {
          if (option?.isActive) {
            outputArray.push({
              type: option?.type,
              value: option?.value,
            });
          }
        });
      } else if (filter.isActive) {
        outputArray.push({
          type: filter?.type,
          value: filter?.value,
        });
      }
    });
    return outputArray;
  };

  return createAvoArray(optionsArray);
};

const sortDefinitions = {
  featured: { value: 'Featured', type: 'featured' },
  popularity: { value: 'Popularity', type: 'popularity' },
  newest: { value: 'Newest', type: 'newest' },
  priceLowToHigh: { value: 'Ascending', type: 'price' },
  pricehighToLow: { value: 'Descending', type: 'price' },
  alphabetical: { value: 'Alphabetical', type: 'alphabetical' },
};

export const getSortInAvoFormat = sorts => [sortDefinitions[sorts]];
