export const GET_ALL_ACTIVE_OPTION_VALUES_RAW = `
  query {
    allActiveOptionValues: getAllActiveOptionsValues {
      id
      value
      image
      cover
      description
      pet_friendly
      price_type
      tier
      sku
      popular_exclude
      exclude_from_sale
      performance_fabric
      sustainable_fabric
      color
      is_active
      group {
        id
        cleaning_code
        description
        guide_description
        material
        material_description
        name
        sort_order
        pet_friendly
        short_description
        star
        images {
          id
          name
          description
          sort_order
        }
      }
    }
  }
`;
