import { useMemo } from 'react';
import getStoreDetails from 'commons/getStoreDetails';
import useIsBrowser from 'hooks/useIsBrowser';

const useStoreDetails = () => {
  const isBrowser = useIsBrowser();

  const storeDetails = useMemo(() => (isBrowser ? getStoreDetails() : null), [
    isBrowser,
  ]);

  return storeDetails;
};

export default useStoreDetails;
