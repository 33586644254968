import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import TabletAndDesktopOnly from 'components/consumer/TabletAndDesktopOnly';

import IconFilter from 'components/icons/iconFilter';

const DesktopShowHideFiltersButton = ({ loading, onClick }) => (
  <TabletAndDesktopOnly>
    <button
      type="button"
      onClick={onClick}
      className={classNames(
        'hidden md:flex items-center justify-center w-8 h-8 rounded-[16px] !bg-gray-light8',
        {
          'mt-2': loading,
        }
      )}
    >
      <IconFilter width={12} height={12} />
      <span className="sr-only">Show/hide filters</span>
    </button>
  </TabletAndDesktopOnly>
);

DesktopShowHideFiltersButton.propTypes = {
  onClick: PropTypes.func,
  loading: PropTypes.bool,
};

export default DesktopShowHideFiltersButton;
