import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import useRandomId from 'hooks/useRandomId';
import IconArrowDownSimple from 'components/icons/iconArrowDownSimple';
import IconClose from 'components/icons/iconClose';
import { colorBrandDark } from 'styles/layout/colors';
import LabeledFieldWrapper from '../LabeledFieldWrapper';

const LabeledSelectBox = React.forwardRef(
  (
    {
      autoComplete,
      className = '',
      errorMessage,
      fullHeight = false,
      id: customInputId,
      label,
      noMargin = false,
      onChange,
      options = [],
      prefixOptionsWithLabel = false,
      showBlankOption = true,
      showClearButtonOverlay = false,
      showSingleLineLabelOverlay = false,
      size = 'md',
      value = '',
    },
    ref
  ) => {
    const randomInputId = useRandomId('LabeledSelectBox__input');
    const id = customInputId || randomInputId;

    const optionsToShow = showBlankOption
      ? [
          {
            value: '',
            label: '',
          },
          ...options,
        ]
      : options;

    return (
      <LabeledFieldWrapper
        className={className}
        errorMessage={errorMessage}
        hasValue={!!value}
        inputId={id}
        label={label}
        noMargin={noMargin}
        shrinkLabelOnFocus={false}
        size={size}
        fullHeight={fullHeight}
      >
        <select
          aria-describedby={`${id}-error`}
          aria-invalid={!!errorMessage || undefined}
          autoComplete={autoComplete}
          className={classNames(
            'appearance-none [background:none] border-0 [font-size:inherit] [font-weight:inherit] h-full tracking-[inherit] leading-[inherit] w-full focus:outline-0 [&:-webkit-autofill]:![-webkit-box-shadow:0_0_0_1000px_white_inset]',
            {
              'pl-[14px]': size !== 'xs' && showSingleLineLabelOverlay,
              'pl-[10px]': size === 'xs' && showSingleLineLabelOverlay,
              'pt-[17px] absolute top-0 left-0': showSingleLineLabelOverlay,
              'pt-[4px]': !showSingleLineLabelOverlay
            }
          )}
          data-xc="LabeledSelectBox"
          id={id}
          onChange={onChange}
          value={value}
          ref={ref}
        >
          {optionsToShow.map(option => (
            <option key={option.label} value={option.value}>
              {prefixOptionsWithLabel && option.label
                ? `${label} ${option.label}`
                : option.label}
            </option>
          ))}
        </select>

        <IconArrowDownSimple
          className={classNames(
            'h-full pointer-events-none absolute top-0 w-3.5 [&_path]:fill-gray',
            {
              'right-5': size !== 'xs',
              'right-3': size === 'xs',
            }
          )}
        />

        <div
          className={classNames(
            'items-center bg-white rounded-lg flex justify-between py-[5px] absolute bottom-0.5 left-0.5 pointer-events-none right-10 top-0.5',
            {
              'px-[13px] text-base': size !== 'xs',
              'text-sm leading-[1.1] font-bold px-2.5': size === 'xs',
              'rounded-sm': size === 'sm',
              invisible: !showSingleLineLabelOverlay,
            }
          )}
          aria-hidden
        >
          <span>
            {`${label} ${optionsToShow.find(option => option.value === value)
              ?.label ?? ''}`}
          </span>
        </div>

        {showClearButtonOverlay && !!value && (
          <div
            className={classNames(
              'items-center bg-white bottom-0 flex justify-between left-0 px-[13px] py-[5px] absolute right-0 top-0 text-base font-bold border border-solid border-brand text-brand-dark cursor-pointer',
              {
                'rounded-lg': size !== 'sm',
                'rounded-sm': size === 'sm',
              }
            )}
            onClick={() => {
              onChange({
                target: {
                  value: '',
                },
              });
            }}
            aria-hidden
          >
            <span>
              {`${label} ${
                optionsToShow.find(option => option.value === value)?.label
              }`}
            </span>

            <IconClose color={colorBrandDark} height={12} width={12} />
          </div>
        )}
      </LabeledFieldWrapper>
    );
  }
);

LabeledSelectBox.propTypes = {
  autoComplete: PropTypes.string,
  className: PropTypes.string,
  errorMessage: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string.isRequired,
  noMargin: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
      ]).isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  showBlankOption: PropTypes.bool,
  showClearButtonOverlay: PropTypes.bool,
  showSingleLineLabelOverlay: PropTypes.bool,
  size: PropTypes.oneOf(['xs', 'sm', 'md']),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  fullHeight: PropTypes.bool,
};

export default LabeledSelectBox;
