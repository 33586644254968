import React from 'react';
import PropTypes from 'prop-types';

import Modal from '../Modal';

const JBIFrameHolder = props => {
  if (!props.script) return null;

  return (
    <div className="[&_iframe]:absolute [&_iframe]:left-0 [&_iframe]:right-0 [&_iframe]:bottom-0 [&_iframe]:top-0 [&_iframe]:border-0 [&_iframe]:z-[2000]">
      <Modal
        open={props.isOpen}
        center
        onClose={() => props.onClose(false)}
        closeOnOverlayClick
        closeOnEsc
        blockScroll
        shouldCloseOnOverlayClick
        styles={{
          width: '90%',
          height: '90%',
          overlay: {
            zIndex: 1003,
          },
          closeIcon: {
            cursor: 'pointer',
          },
        }}
      >
        <div
          className="h-[90vh] w-[90vw] max-w-[1100px] max-[1100px]:w-[80vw] max-[1100px]:max-w-full"
          dangerouslySetInnerHTML={{ __html: props.script }}
        />
      </Modal>
    </div>
  );
};

JBIFrameHolder.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  script: PropTypes.string,
};

export default JBIFrameHolder;
