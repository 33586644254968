import React, { useEffect, useState } from 'react';
import { usePrevious } from 'react-use';

import LabeledTextInput from 'components/consumer/LabeledTextInput';
import useProductListingPageContext from 'data-hooks/useProductListingPageContext';
import IconSearch from 'components/icons/IconSearch';
import { colorGrayLight2 } from 'styles/layout/colors';
import useScreen from 'hooks/useScreen';
import getShortPageName from './helpers/getShortPageName';

const DesktopProductListingSearchFilter = () => {
  const {
    filters,
    pageName = '',
    setCustomFilter,
  } = useProductListingPageContext();

  const { isMobileOrTablet } = useScreen();

  const shortPageName = getShortPageName(pageName);

  const valueInPageState = filters.find(({ id }) => id === 'search')?.value;
  const prevValueInPageState = usePrevious(valueInPageState);

  // We store the value locally so we can update the local state immediately
  // but throttle the update to PLP state in context for performance
  const [value, setValue] = useState(valueInPageState ?? '');

  useEffect(() => {
    setValue(latestValue => {
      // If the value in PLP state was previously truthy but changes to falsy,
      // this indicates that the filter state has been reset so we should reset
      // the local state.
      if (
        prevValueInPageState &&
        !valueInPageState &&
        // Only reset if local state is truthy
        !!latestValue &&
        // Only reset if local state has already been persisted to PLP state
        latestValue === prevValueInPageState
      ) {
        return '';
      }

      return latestValue;
    });
  }, [prevValueInPageState, value, valueInPageState]);

  return (
    <LabeledTextInput
      action={<IconSearch color={colorGrayLight2} height={20} />}
      actionAlignment="left"
      label={!isMobileOrTablet ? `Search ${shortPageName}` : `Search`}
      labelSize="sm"
      noMargin
      onChange={event => {
        const updatedValue = event?.target?.value;
        setValue(updatedValue);
        setCustomFilter('search', updatedValue);
      }}
      value={value ?? null}
    />
  );
};

export default DesktopProductListingSearchFilter;
