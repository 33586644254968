import React from 'react';
import PropTypes from 'prop-types';
import { isNil } from 'lodash';

import TextPlaceholder from 'components/consumer/TextPlaceholder';

const ProductListingResultsCount = ({ loading = false, totalProductCount }) => {
  if (loading) {
    return <TextPlaceholder className="!w-[75px] !h-[15px]" />;
  }

  if (isNil(totalProductCount)) {
    return null;
  }

  return (
    <span className="text-sm text-gray-light2 md:relative md:self-center">
      {totalProductCount} Results
    </span>
  );
};

ProductListingResultsCount.propTypes = {
  loading: PropTypes.bool,
  totalProductCount: PropTypes.number,
};

export default ProductListingResultsCount;
