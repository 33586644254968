import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import useProductListingPageContext from 'data-hooks/useProductListingPageContext';
import IconFilter from 'components/icons/iconFilter2';
import { colorGray, colorWhite } from 'styles/layout/colors';
import MobileOnly from 'components/consumer/MobileOnly';

const MobileProductListingFiltersButton = ({ onClick }) => {
  const { activeFilterCount } = useProductListingPageContext();

  const hasActiveFilters = activeFilterCount > 0;

  return (
    <MobileOnly>
      <button
        type="button"
        className={classNames(
          'md:hidden text-sm font-bold items-center rounded-sm flex justify-center mr-[6px] p-3 border-[1px] max-h-12',
          {
            'bg-brand border-brand text-white': hasActiveFilters,
            'bg-white border-gray-light3 text-gray': !hasActiveFilters,
          }
        )}
        onClick={onClick}
      >
        <IconFilter
          className="mr-[6px]"
          color={hasActiveFilters ? colorWhite : colorGray}
        />

        <span
          className={classNames('h-full', {
            'text-gray': !hasActiveFilters,
            'text-white': hasActiveFilters,
          })}
        >
          <span>Filters</span>
          {activeFilterCount > 0 && ` (${activeFilterCount})`}
        </span>
      </button>
    </MobileOnly>
  );
};

MobileProductListingFiltersButton.propTypes = {
  onClick: PropTypes.func,
};

export default MobileProductListingFiltersButton;
