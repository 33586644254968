import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import CtaButton from 'components/essentials/Cta/CtaButton';
import useMediumAndLongPress from 'hooks/useMediumAndLongPress';

const ProductListingLoadMoreButton = ({
  clickCount,
  isLoading,
  onClick,
  onLongPress,
}) => {
  const {
    onMouseDown,
    onMouseLeave,
    onMouseUp,
    onTouchEnd,
    onTouchStart,
    hasMediumPressed,
  } = useMediumAndLongPress({ onClick, onLongPress });

  const detectLongPresses = !!onLongPress;

  const longPressEventHandlers = detectLongPresses
    ? {
        onMouseDown,
        onMouseLeave,
        onMouseUp,
        onTouchEnd,
        onTouchStart,
      }
    : {};

  return (
    <div className={classNames('flex p-2 relative')}>
      <span className="z-20 bg-white p-[5px] rounded-md">
        <CtaButton
          data-click-count={clickCount}
          // If we're detecting long presses, the onClick handler will be
          // handled by the long press hook so should not be triggered here
          onClick={!detectLongPresses ? onClick : () => {}}
          isLoading={isLoading}
          styling="outline-button"
          {...longPressEventHandlers}
        >
          Load More
        </CtaButton>
      </span>

      <span
        className={classNames(
          'absolute inset-[5px] p-[5px] pointer-events-none z-10 rounded-lg',
          '[mask:linear-gradient(#fff_0_0)_content-box,linear-gradient(#fff_0_0)]',
          "before:block before:top-1/2 before:left-1/2 before:content-[''] before:absolute  before:w-[calc(100%_*_1.41421356237)] before:pb-[calc(100%_*_1.41421356237)] before:-translate-x-1/2 before:-translate-y-1/2 before:bg-conic-rainbow before:animate-border-spin",
          {
            'opacity-0': !hasMediumPressed,
            'animate-fade-in': onLongPress && hasMediumPressed,
          }
        )}
      />
    </div>
  );
};

ProductListingLoadMoreButton.propTypes = {
  clickCount: PropTypes.number,
  isLoading: PropTypes.bool,
  onClick: PropTypes.func,
  onLongPress: PropTypes.func,
};

export default ProductListingLoadMoreButton;
