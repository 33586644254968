import React, { useState } from 'react';
import PropTypes from 'prop-types';

import useProductListingPageContext from 'data-hooks/useProductListingPageContext';
import { PRODUCT_LISTING_MAX_FILTER_OPTIONS } from 'commons/constants';
import VisuallyHidden from 'components/consumer/VisuallyHidden';
import Checkbox from 'components/consumer/Checkbox';

const DesktopProductListingListFilter = ({ id }) => {
  const { filters = [], toggleFilterOption } = useProductListingPageContext();

  const [showAllOptions, setShowAllOptions] = useState(false);

  const toggleShowAllOptions = () => {
    setShowAllOptions(prevShowAllOptions => !prevShowAllOptions);
  };

  const currentFilter = filters.find(filter => id === filter.id);

  if (!currentFilter) {
    return null;
  }

  const isCategoryFilter = id === 'category';
  const { label, options } = currentFilter;

  const expandableOptions =
    !isCategoryFilter && options.length > PRODUCT_LISTING_MAX_FILTER_OPTIONS;

  const optionsToShow =
    expandableOptions && !showAllOptions
      ? options.slice(0, PRODUCT_LISTING_MAX_FILTER_OPTIONS)
      : options;

  return (
    <div>
      <VisuallyHidden>
        Clicking on the following checkboxes will update the content.
      </VisuallyHidden>

      <div className="space-y-3">
        {optionsToShow.map(
          ({ id: optionId, label: optionLabel, isActive, isDisabled }) => (
            <div key={optionId}>
              <Checkbox
                checked={isActive}
                disabled={isDisabled}
                id={optionId}
                key={id}
                onClick={() => {
                  if (isDisabled) {
                    return;
                  }

                  toggleFilterOption(id, optionId);
                }}
                size="sm"
              >
                {optionLabel}
              </Checkbox>
            </div>
          )
        )}
      </div>

      {expandableOptions && (
        <div>
          <button
            type="button"
            className="text-sm font-bold text-brand mt-3"
            onClick={toggleShowAllOptions}
            aria-label={
              !showAllOptions
                ? `View more ${label} items`
                : `View fewer ${label} items`
            }
          >
            {!showAllOptions ? 'View More' : 'View Less'}
          </button>
        </div>
      )}
    </div>
  );
};

DesktopProductListingListFilter.propTypes = {
  id: PropTypes.string,
};

export default DesktopProductListingListFilter;
