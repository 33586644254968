import React from 'react';
import PropTypes from 'prop-types';
import loadable from '@loadable/component';

import BrowserOnly from 'components/consumer/BrowserOnly';

const RecentlyViewed = loadable(() =>
  import('components/consumer/recently-viewed')
);

const CategoryBestSellersCarousel = loadable(() =>
  import('components/consumer/ProductCardCarousel/CategoryBestSellersCarousel')
);
const RecommendedForYouCarousel = loadable(() =>
  import('components/consumer/ProductCardCarousel/RecommendedForYouCarousel')
);

const ProductListingPageWidgets = ({ categoryId, loading = false }) => {
  if (loading) {
    return null;
  }

  return (
    <BrowserOnly>
      <div>
        <div className="py-10 2xl:py-16">
          <RecentlyViewed />
        </div>

        {!!categoryId && (
          <CategoryBestSellersCarousel categoryId={parseInt(categoryId, 10)} />
        )}

        <RecommendedForYouCarousel />
      </div>
    </BrowserOnly>
  );
};

ProductListingPageWidgets.propTypes = {
  categoryId: PropTypes.number,
  loading: PropTypes.bool,
};

export default ProductListingPageWidgets;
