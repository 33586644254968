import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const ValidationError = ({
  'data-xc': dataXc,
  align,
  block,
  children,
  className,
}) => (
  <span
    className={classNames(
      'text-xs text-red px-0 py-0.5 [&_span]:block [&_span]:mb-4',
      {
        [className]: className,
        'text-right': align === 'right',
        block,
      }
    )}
    data-xc={dataXc}
  >
    {children}
  </span>
);

ValidationError.propTypes = {
  'data-xc': PropTypes.string,
  align: PropTypes.oneOf(['right']),
  block: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
};

export default ValidationError;
