/* eslint-disable react-hooks/rules-of-hooks */

// This is a rare case where it is okay to conditionally render a hook, because
// the NEXT_PUBLIC_IS_NEXT_APP environment variable is always true in the new
// version of the app, and always false in the old version of the app.

import { useId, useMemo } from 'react';
import getRandomId from 'commons/getRandomId';
import isNewConsumerApp from 'commons/isNewConsumerApp';

const useRandomId = (prefix = 'random-id-') => {
  if (isNewConsumerApp) {
    const id = useId();
    return `${prefix}-${id}`;
  }

  const randomId = useMemo(() => getRandomId(prefix), [prefix]);

  return randomId;
};

export default useRandomId;
