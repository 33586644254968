import { useCallback } from 'react';
import { useSetAtom } from 'jotai';

import mobileColumnCountAtom from './mobileColumnCountAtom';

const useToggleMobileColumnCount = () => {
  const setMobileColumnCount = useSetAtom(mobileColumnCountAtom);

  const toggleMobileColumnCount = useCallback(() => {
    setMobileColumnCount(prevMobileColumnCount =>
      prevMobileColumnCount === '1' ? '2' : '1'
    );
  }, [setMobileColumnCount]);

  return toggleMobileColumnCount;
};

export default useToggleMobileColumnCount;
