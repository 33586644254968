import avo from 'analytics/avo';
import isClient from 'commons/isClient';
import avoInspector from 'analytics/avoInspector';
import RudderstackAvoDestination from 'analytics/RudderstackAvoDestination';
import getEnvValue from 'commons/getEnvValue';

const VERSION =
  process.env.NEXT_PUBLIC_VERSION ?? getEnvValue('REACT_APP_VERSION') ?? '';

const initAvo = () => {
  if (isClient()) {
    avo.initAvo(
      {
        env:
          VERSION === 'development' ||
          VERSION === 'test' ||
          VERSION === 'staging'
            ? 'dev'
            : VERSION === 'production'
            ? 'prod'
            : VERSION,
        inspector: avoInspector,
        strict: false,
      },
      {
        userAgent: navigator.userAgent,
      },
      null,
      RudderstackAvoDestination
    );
  }
};

export default initAvo;
