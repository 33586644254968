import React from 'react';

import IconMobileGrid1x from 'components/icons/iconMobileGrid1x';
import IconMobileGrid2x from 'components/icons/iconMobileGrid2x';
import MobileOnly from 'components/consumer/MobileOnly';
import useMobileColumnCount from '../../state/mobileColumnCount/useMobileColumnCount';
import useToggleMobileColumnCount from '../../state/mobileColumnCount/useToggleMobileColumnCount';

const MobileGridColumnToggle = () => {
  const mobileColumnCount = useMobileColumnCount();
  const toggleMobileColumnCount = useToggleMobileColumnCount();

  return (
    <MobileOnly>
      <button
        type="button"
        className="flex md:hidden items-center justify-center min-w-[29px]"
        onClick={toggleMobileColumnCount}
      >
        {mobileColumnCount === '1' ? (
          <IconMobileGrid1x />
        ) : (
          <IconMobileGrid2x />
        )}
      </button>
    </MobileOnly>
  );
};

export default MobileGridColumnToggle;
