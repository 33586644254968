import { PDP_CUSTOMIZE_TAB_ID } from 'commons/constants';
import usePdpAtom from '../usePDPAtom';

const usePdpSelectedCustomizerTabAtom = () => {
  const pdpSelectedCustomizerTabAtom = usePdpAtom(
    'pdpSelectedCustomizerTabAtom',
    PDP_CUSTOMIZE_TAB_ID
  );

  return pdpSelectedCustomizerTabAtom;
};

export default usePdpSelectedCustomizerTabAtom;
