import React from 'react';

import useProductListingPageContext from 'data-hooks/useProductListingPageContext';
import MobileProductListingFilter from './MobileProductListingFilter';

// On mobile, we want to show the quickship filter at the top, the color filter
// at the bottom, and we should not show the search filter at all.
const getFiltersInMobileOrder = filters => {
  const quickshipFilter = filters.find(({ id }) => id === 'quickship');
  const colorFilter = filters.find(({ id }) => id === 'color');
  const widthRangeFilter = filters.find(({ id }) => id === 'widthRange');

  const filtersWithoutQuickshipColorSearchAndWidthRange = filters.filter(
    filter =>
      !['quickship', 'color', 'search', 'widthRange'].includes(filter.id)
  );

  const orderedFilters = [
    quickshipFilter,
    widthRangeFilter,
    ...filtersWithoutQuickshipColorSearchAndWidthRange,
    colorFilter,
  ].filter(filter => !!filter);

  return orderedFilters;
};

const MobileProductListingFilters = () => {
  const { filters = [] } = useProductListingPageContext();
  const orderedFilters = getFiltersInMobileOrder(filters);

  return (
    <div>
      {orderedFilters.map(({ id, label }) => (
        <MobileProductListingFilter id={id} key={id} label={label} />
      ))}
    </div>
  );
};

export default MobileProductListingFilters;
