import React, { useState } from 'react';

import useProductListingPageContext from 'data-hooks/useProductListingPageContext';
import CtaButton from 'components/essentials/Cta/CtaButton';
import useScreen from 'hooks/useScreen';
import useIsBrowser from 'hooks/useIsBrowser';
import ProductListingLoadMoreButton from './ProductListingLoadMoreButton';

const ProductListingLoadMoreButtons = () => {
  const {
    loadAllProducts,
    loadMoreClickCount,
    loadMoreProducts,
    showLoadMoreButton,
    showLoadMoreButtonSpinner,
  } = useProductListingPageContext();

  const [showLoadAllButtonSpinner, setShowLoadAllButtonSpinner] = useState(
    false
  );

  const isBrowser = useIsBrowser();
  const { isTabletOrDesktop } = useScreen();

  if (!showLoadMoreButton) {
    return null;
  }

  const loadAllProductsAndShowSpinner = () => {
    setShowLoadAllButtonSpinner(true);

    setTimeout(() => {
      loadAllProducts();
    }, 10);
  };

  const showLoadAllButton =
    isBrowser && isTabletOrDesktop && loadMoreClickCount >= 2;

  return (
    <div className="flex justify-center items-center gap-4">
      <ProductListingLoadMoreButton
        clickCount={loadMoreClickCount}
        onClick={loadMoreProducts}
        onLongPress={isTabletOrDesktop ? loadAllProductsAndShowSpinner : null}
        isLoading={
          showLoadMoreButtonSpinner ||
          (!showLoadAllButton && showLoadAllButtonSpinner)
        }
      />

      {showLoadAllButton && (
        <div className="flex p-2">
          <CtaButton
            color="brand"
            onClick={loadAllProductsAndShowSpinner}
            isLoading={showLoadAllButtonSpinner}
            styling="solid-button"
          >
            Show All
          </CtaButton>
        </div>
      )}
    </div>
  );
};

export default ProductListingLoadMoreButtons;
