import React from 'react';

import useProductListingPageContext from 'data-hooks/useProductListingPageContext';
import ProductListingClearAllFiltersButton from '../ProductListingClearAllFiltersButton';
import ProductListingQuickshipFilter from '../ProductListingQuickshipFilter';
import DesktopProductListingListFilter from './DesktopProductListingListFilter';
import DesktopProductListingSearchFilter from './DesktopProductListingSearchFilter';
import ProductListingWidthRangeFilter from '../ProductListingWidthRangeFilter';

const DesktopProductListingSidebarFilters = () => {
  const { filters = [] } = useProductListingPageContext();

  // Remove color filter because on desktop we render it in the header instead
  const filtersWithoutColors = filters.filter(filter => filter.id !== 'color');

  return (
    <div className="pb-4">
      {/* Vertical padding for the Clear All button is handled within the
          ProductListingClearAllFiltersButton component so that the padding
          shows and hides at the same time as the button */}
      <ProductListingClearAllFiltersButton />

      <div className="flex flex-col gap-5">
        {filtersWithoutColors.map(({ id, label }) => {
          if (id === 'search') {
            return <DesktopProductListingSearchFilter key={id} />;
          }

          if (id === 'quickship') {
            return <ProductListingQuickshipFilter key={id} />;
          }

          if (id === 'widthRange') {
            return <ProductListingWidthRangeFilter key={id} showTitle />;
          }

          return (
            <div key={id}>
              <h3 className="text-base font-bold mb-2">{label}</h3>
              <DesktopProductListingListFilter id={id} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default DesktopProductListingSidebarFilters;
