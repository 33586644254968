/* eslint-disable no-underscore-dangle */
import isClient from 'commons/isClient';

const isRunningOnClient = isClient();

const Inspector = isRunningOnClient ? require('avo-inspector') : null;

const version = isRunningOnClient
  ? process.env.NEXT_PUBLIC_VERSION ?? window?._env_?.REACT_APP_VERSION
  : '';

const avoInspector = isRunningOnClient
  ? new Inspector.AvoInspector({
      apiKey: '0LwnxMrVza9UwOgaWeUP',
      env:
        version === 'development' || version === 'test'
          ? 'dev'
          : version === 'production'
          ? 'prod'
          : version,
      version: '1.0.0',
      appName: 'My App',
    })
  : null;

export default avoInspector;
