import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router-dom';
import JBIFrameHolder from 'components/essentials/JBIFrameHolder';
import FinancingCtaButton from 'components/essentials/FinancingCtaButton';
import PDPOrderSwatchesSidebar from 'components/consumer/PDP/PDPOrderSwatchesSidebar';
import CtaButton from 'components/essentials/Cta/CtaButton';
import CtaLink from 'components/essentials/Cta/CtaLink';

const ToggleCTALinkButton = props => {
  const { toggleInputType, style = {}, isHovered, heading, noHorizontalPadding = false } = props;
  const CTAComponentType = toggleInputType?.selected?.value
    ? toggleInputType.selected.value?.replace(' ', '')
    : '';

  const [iFrameVisibility, setIFrameVisibility] = useState(false);
  const [isOrderSwatchesSidebarOpen, setIsOrderSwatchesSidebarOpen] = useState(
    false
  );

  const isButtonEmbedded = !!(
    toggleInputType?.selected &&
    CTAComponentType === 'CTAButton' &&
    toggleInputType.ctaButton.embedCode.enabled &&
    toggleInputType.ctaButton.embedCode.value.includes('<a ')
  );

  let finalCTAButton = null;

  if (
    toggleInputType &&
    toggleInputType.selected &&
    CTAComponentType === 'CTAButton' &&
    toggleInputType.ctaButton
  ) {
    if (
      toggleInputType.ctaButton.type &&
      (toggleInputType.ctaButton.type === 'Affirm' ||
        toggleInputType.ctaButton.type === 'Financing')
    ) {
      finalCTAButton = (
        <FinancingCtaButton
          style={style}
          color={toggleInputType.ctaButton.color || 'black'}
          styling={toggleInputType.ctaButton.style || 'solid-button'}
        >
          {toggleInputType.ctaButton.content}
        </FinancingCtaButton>
      );
    } else if (
      toggleInputType.ctaButton.type &&
      toggleInputType.ctaButton.type === 'SwatchRequestForm'
    ) {
      finalCTAButton = (
        <CtaButton
          onClick={() => {
            setIsOrderSwatchesSidebarOpen(true);
          }}
          color={toggleInputType.ctaButton.color || 'black'}
          styling={toggleInputType.ctaButton.style || 'solid-button'}
          style={style}
          isHovered={isHovered}
        >
          {toggleInputType.ctaButton.content}
        </CtaButton>
      );
    } else {
      finalCTAButton = (
        <CtaButton
          onClick={() => {
            setIFrameVisibility(true);
          }}
          color={toggleInputType.ctaButton.color || 'black'}
          styling={toggleInputType.ctaButton.style || 'solid-button'}
          style={style}
          isHovered={isHovered}
        >
          {toggleInputType.ctaButton.content}
        </CtaButton>
      );
    }
  }

  return (
    <>
      {isOrderSwatchesSidebarOpen && (
        <PDPOrderSwatchesSidebar
          show={isOrderSwatchesSidebarOpen}
          onClose={() => {
            setIsOrderSwatchesSidebarOpen(false);
          }}
          closeSlidePanel={() => {
            setIsOrderSwatchesSidebarOpen(false);
          }}
        />
      )}
      {toggleInputType &&
        toggleInputType.selected &&
        CTAComponentType === 'CTALink' &&
        !!toggleInputType.ctaLink &&
        toggleInputType.ctaLink.content && (
          <div>
            <CtaLink
              to={toggleInputType.ctaLink.linkTo}
              color={toggleInputType.ctaLink.color}
              styling={toggleInputType.ctaLink.style || 'solid-button'}
              style={style}
              isHovered={isHovered}
              heading={heading}
              noHorizontalPadding={noHorizontalPadding}
            >
              {toggleInputType.ctaLink.content}
            </CtaLink>
          </div>
        )}
      {toggleInputType?.selected &&
        CTAComponentType === 'CTAButton' &&
        toggleInputType.ctaButton?.content && (
          <div>
            {toggleInputType.ctaButton.embedCode.enabled && isButtonEmbedded && (
              <div
                className="embed-button"
                dangerouslySetInnerHTML={{
                  __html: toggleInputType.ctaButton.embedCode.value,
                }}
              />
            )}
            {toggleInputType.ctaButton.embedCode.enabled &&
              !isButtonEmbedded &&
              iFrameVisibility && (
                <JBIFrameHolder
                  isOpen={iFrameVisibility}
                  onClose={setIFrameVisibility}
                  script={toggleInputType.ctaButton.embedCode.value || ''}
                />
              )}
            {!isButtonEmbedded && finalCTAButton}
          </div>
        )}
    </>
  );
};

ToggleCTALinkButton.propTypes = {
  toggleInputType: PropTypes.shape({
    selected: PropTypes.shape({
      value: PropTypes.string,
    }),
    ctaButton: PropTypes.shape({
      content: PropTypes.string,
      embedCode: PropTypes.shape({
        enabled: PropTypes.bool,
        value: PropTypes.string,
      }),
      type: PropTypes.string,
      color: PropTypes.string,
      style: PropTypes.string,
    }),
    ctaLink: PropTypes.shape({
      content: PropTypes.string,
      linkTo: PropTypes.string,
      color: PropTypes.string,
      style: PropTypes.string,
    }),
  }),
  style: PropTypes.shape({
    height: PropTypes.string,
    width: PropTypes.string,
  }),
  isHovered: PropTypes.bool,
  noHorizontalPadding: PropTypes.bool,
  heading: PropTypes.string,
};

export default withRouter(ToggleCTALinkButton);