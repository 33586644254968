import { gql } from '@apollo/client';

export const PRODUCT_BASIC_DETAILS = gql`
  query getProductBasicDetails(
    $slug: String
    $id: Int
    $productSku: String
    $regionId: String
    $stateCode: String
  ) {
    basicDetails: getProduct(slug: $slug, id: $id, productSku: $productSku) {
      id
      sku
      slug
      name
      discontinued
      default_option_value
      default_option_image
      first_popular_image
      exclude_from_sale
      type {
        name
      }
      family {
        name
      }
      efg_flag
      exp_wood_product
      difficulty
      is_external
      category_ids
      attributes_min {
        dimensions
        urlKey
      }
      attributes {
        code
        label
        name
        value
      }
      additional_attributes {
        fet_left_img
        fet_right_img
        showpiece_desc
      }
      hero_image_count
      hero_images {
        sort_order
        img {
          url
          cropData
        }
        upholstery_name
        upholstery_type
      }
      variation_photos_count
      custom_properties {
        dimensional_image_desktop
        dimensional_image_mobile
        selling_attributes
        product_label
        intro_video_1x1
        intro_video_16x9
        video_1x1
        video_3x2
        ar_asset
        ar_asset_upholstery
        display_option_images
        default_angle
        cylindo_product
        cylindo_scale_up
        option_selector
        campaign
        marxent_product
      }
      meta {
        id
        price(stateCode: $stateCode, regionId: $regionId) {
          discount_percent
          final_price
          original_price
          max_discount_percent
        }
        reviews {
          average_score
          count
          sample_reviews {
            title
            comment
            rate
            date
            full_name
          }
        }
        promotion {
          discount
          startDate
          endDate
          tiers {
            min_cart
            discount
          }
        }
        collectionSlug
      }
    }
  }
`;

export const PRODUCT_AVAILABLE_SIZES = gql`
  query getProductAvailableSizes($slug: String, $id: Int, $productSku: String) {
    availableSizes: getProduct(slug: $slug, id: $id, productSku: $productSku) {
      id
      available_sizes {
        id
        slug
      }
    }
  }
`;

export const PRODUCT_OPTIONS = gql`
  query getProductOptions($slug: String, $id: Int, $productSku: String) {
    productOptions: getProduct(slug: $slug, id: $id, productSku: $productSku) {
      id
      slug
      options {
        id
        product_id
        name
        original_name
        default_value
        popular_values
        values {
          variant_id
          option_values {
            id
            sku
            value
            additional_cost
            variant_id
            is_active
            additional_visibility
          }
        }
        totalValuesCount
      }
    }
  }
`;

export const ALL_CUSTOMER_PHOTOS_AND_REVIEWS = gql`
  query getProductAllCustomerPhotosAndReviews($id: Int) {
    allCustomerPhotosAndReviews: getProductAllCustomerPhotosAndReviews(
      id: $id
    ) {
      customer_photos {
        id
        url
        full_name
        location
        comment
        upholstery_id
      }
      reviews {
        id
        title
        comment
        date
        full_name
        location
        rate
        variant
        materials
      }
    }
  }
`;

export const PRODUCT_LOCAL_STOCK = gql`
  query getProductLocalStock(
    $slug: String
    $id: Int
    $zipCode: String
    $fromCache: Boolean
    $productSku: String
    $warehouseId: String
  ) {
    productLocalStock: getProduct(
      slug: $slug
      id: $id
      productSku: $productSku
    ) {
      id
      qs_stock(
        zipCode: $zipCode
        fromCache: $fromCache
        warehouseId: $warehouseId
      ) {
        stock
        errors {
          message
        }
      }
    }
  }
`;

export const PRODUCT_FAVORITES_META = gql`
  query getProductFavorites($id: Int, $slug: String, $productSku: String) {
    productFavorites: getProduct(
      slug: $slug
      id: $id
      productSku: $productSku
    ) {
      id
      meta {
        id
        customer {
          favorited
          favOptions {
            optionName
            optionId
            optionType
          }
        }
      }
    }
  }
`;

export const PRODUCT_PROTECTION_PLANS_META = gql`
  query getProductProtectionPlans(
    $id: Int
    $slug: String
    $productSku: String
  ) {
    productProtectionPlans: getProduct(
      slug: $slug
      id: $id
      productSku: $productSku
    ) {
      id
      meta {
        id
        eligible_protection_planids
      }
    }
  }
`;

export const PRODUCT_CUSTOMER_PHOTOS_REVIEWS_MIN = gql`
  query getProductCustomerPhotosAndReviewsMin(
    $id: Int
    $slug: String
    $productSku: String
  ) {
    productCustomerPhotosAndReviewsMin: getProduct(
      slug: $slug
      id: $id
      productSku: $productSku
    ) {
      id
      name
      customer_photos_min {
        photos {
          id
          url
          upholstery_id
        }
        totalPhotos
      }
      customer_reviews {
        reviews {
          id
          title
          comment
          date
          full_name
          location
          rate
          materials
        }
        totalReviews
        averageRating
      }
    }
  }
`;

export const PRODUCT_LAYOUT_WIDGETS = gql`
  query getProductLayoutWidgets($id: Int, $slug: String, $productSku: String) {
    productLayoutWidgets: getProduct(
      slug: $slug
      id: $id
      productSku: $productSku
    ) {
      id
      layout_widgets {
        widget_identifier
        fields
      }
    }
  }
`;

export const GET_UPSELL_PRODUCT = gql`
  query getUpSellProduct(
    $id: Int
    $slug: String
    $regionId: String
    $stateCode: String
  ) {
    getUpSellProduct(id: $id, slug: $slug) {
      main_product {
        id
        slug
      }
      upsell_product {
        product {
          id
          name
          slug
          family {
            name
          }
          type {
            name
          }
          attributes {
            code
            label
            name
            value
          }
          hero_images_min {
            sort_order
            url
          }
          images {
            sort_order
            url
          }
          variation_photos_count
          meta {
            id
            customer {
              favorited
            }
            price(stateCode: $stateCode, regionId: $regionId) {
              discount_percent
              final_price
              original_price
              max_discount_percent
            }
            promotion {
              discount
              endDate
              startDate
              tiers {
                min_cart
                discount
              }
            }
            shipping {
              quick_ship
            }
          }
          options: options_extended {
            id
            name
            default_value
            values {
              variant_id
              option_values {
                id
                value
                image
                cover
                description
                pet_friendly
                additional_cost
                variant_id
                price_type
                tier
                sku
                popular_exclude
                performance_fabric
                sustainable_fabric
                color
                is_active
              }
            }
          }
        }
        product_id
        product_total_reviews
        product_average_rating
        upsell_description
        upsell_header
        upsell_image
        upsell_flyout_image
        upsell_flyout_description
      }
      errors {
        message
      }
    }
  }
`;
