import { useAtomValue } from 'jotai';

import usePdpSelectedCustomizerTabAtom from './usePdpSelectedCustomizerTabAtom';

const usePdpSelectedCustomizerTab = () => {
  const pdpSelectedCustomizerTabAtom = usePdpSelectedCustomizerTabAtom();
  const pdpSelectedCustomizerTab = useAtomValue(pdpSelectedCustomizerTabAtom);

  return pdpSelectedCustomizerTab;
};

export default usePdpSelectedCustomizerTab;
