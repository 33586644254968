import { useCallback, useMemo } from 'react';
import useSWR from 'swr';

import fetcherGraphQL from 'commons/fetcherGraphQL';

const useSWRQuery = (query, options) => {
  const queryObject = useMemo(
    () => ({
      query,
    }),
    [query]
  );

  const fetchQuery = useCallback(async () => fetcherGraphQL(queryObject), [
    queryObject,
  ]);

  const { data, error, isLoading } = useSWR(query || null, fetchQuery, options);

  return {
    data,
    error,
    isLoading: isLoading && !data,
  };
};

export default useSWRQuery;
