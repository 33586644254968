import { useMemo } from 'react';

import useSWRQuery from 'data-hooks/useSWRQuery';
import { GET_ALL_ACTIVE_OPTION_VALUES_RAW } from './queries';
import { DEFAULTS_FIELD_VALUES } from './constants';

const useAllOptionsNew = (skip = false) => {
  const { data, isLoading, error } = useSWRQuery(
    !skip ? GET_ALL_ACTIVE_OPTION_VALUES_RAW : null
  );

  const allActiveOptionValues = useMemo(
    () => data?.allActiveOptionValues ?? [],
    [data?.allActiveOptionValues]
  );

  const allOptionsValues = useMemo(
    () =>
      allActiveOptionValues?.reduce((acc, optionValue) => {
        if (!optionValue) {
          return acc;
        }

        const optionValueWithDefaults = {
          ...DEFAULTS_FIELD_VALUES,
          ...optionValue,
        };

        return {
          ...acc,
          [optionValue.id]: optionValueWithDefaults,
        };
      }, {}),
    [allActiveOptionValues]
  );

  const allOptionsByValuesKey = useMemo(
    () =>
      Object.values(allOptionsValues).reduce((acc, optionValue) => {
        if (!optionValue?.value) {
          return acc;
        }

        const optionValueKey = optionValue.value
          .toLowerCase()
          .trim()
          .split(' ')
          .join('-');

        return {
          ...acc,
          [optionValueKey]: optionValue,
        };
      }, {}),
    [allOptionsValues]
  );

  if (skip) {
    return {
      isLoading,
      error,
      allOptionsValues: null,
      allOptionsByValuesKey: null,
    };
  }

  return {
    isLoading,
    error,
    allOptionsValues,
    allOptionsByValuesKey,
  };
};

export default useAllOptionsNew;
