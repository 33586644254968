import React from 'react';

import BrowserOnly from 'components/consumer/BrowserOnly';
import TabletAndDesktopOnly from 'components/consumer/TabletAndDesktopOnly';
import ProductListingColorFilter from '../ProductListingColorFilter';

const DesktopProductListingColorFilter = () => (
  <BrowserOnly>
    <TabletAndDesktopOnly>
      <div className="hidden md:block">
        <ProductListingColorFilter />
      </div>
    </TabletAndDesktopOnly>
  </BrowserOnly>
);

export default DesktopProductListingColorFilter;
