import React from 'react';
import PropTypes from 'prop-types';

import TogglePillGroup from 'components/consumer/TogglePillGroup';
import useProductListingPageContext from 'data-hooks/useProductListingPageContext';

const MobileProductListingListFilter = ({ id }) => {
  const { filters = [], toggleFilterOption } = useProductListingPageContext();

  const currentFilter = filters.find(filter => id === filter.id);

  if (!currentFilter) {
    return null;
  }

  const { label, options } = currentFilter;

  const activeOptions = options
    .filter(({ isActive }) => isActive)
    .map(option => option.id);

  return (
    <TogglePillGroup
      items={options?.map(option => ({
        ...option,
        onClick: () => {
          toggleFilterOption(id, option.id);
        },
      }))}
      selectedItems={activeOptions}
      groupName={label}
      bindValues
    />
  );
};

MobileProductListingListFilter.propTypes = {
  id: PropTypes.string,
};

export default MobileProductListingListFilter;
