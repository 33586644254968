import { useState } from 'react';
import { useDebounce } from 'react-use';

const useDebouncedValue = (value, delay = 2000) => {
  const [debouncedValue, setDebouncedValue] = useState(null);

  useDebounce(
    () => {
      setDebouncedValue(value);
    },
    delay,
    [value]
  );

  return debouncedValue;
};

export default useDebouncedValue;
