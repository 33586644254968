import React from 'react';
import PropTypes from 'prop-types';

import useProductListingPageContext from 'data-hooks/useProductListingPageContext';
import MobileModal from 'components/consumer/MobileModal';
import CtaButton from 'components/essentials/Cta/CtaButton';
import MobileOnly from 'components/consumer/MobileOnly';
import withBrowserOnly from 'components/hoc/withBrowserOnly';
import MobileProductListingFilters from './MobileProductListingFilters';
import ProductListingClearAllFiltersButton from '../../ProductListingClearAllFiltersButton';

const MobileProductListingFiltersModal = ({ isOpen, onClose }) => {
  const { activeFilterCount, productCount } = useProductListingPageContext();

  return (
    <MobileOnly>
      <div className="md:hidden">
        <MobileModal
          enableHashHistory={false}
          headerRight={<ProductListingClearAllFiltersButton />}
          isFullHeight
          isOpen={isOpen}
          onClose={onClose}
          stickyFooter={
            <CtaButton block onClick={onClose}>
              Done ({productCount || 0} result{productCount === 1 ? '' : 's'})
            </CtaButton>
          }
          title={
            activeFilterCount > 0 ? `Filters (${activeFilterCount})` : 'Filters'
          }
        >
          {isOpen && <MobileProductListingFilters />}
        </MobileModal>
      </div>
    </MobileOnly>
  );
};

MobileProductListingFiltersModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export default withBrowserOnly(MobileProductListingFiltersModal);
