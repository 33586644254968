import { useMemo } from 'react';

import {
  FABRIC_PRODUCT_OPTION_ID,
  PDP_DECIDELATER_TAB_ID,
} from 'commons/constants';
import usePdpSelectedCustomizerTab from 'components/consumer/PDP/state/pdpSelectedCustomizerTab/usePdpSelectedCustomizerTab';
import usePDPBasicDetails from 'components/consumer/PDP/state/context/usePDPBasicDetails';
import usePDPOptions from 'components/consumer/PDP/state/context/usePDPOptions';
import usePDPClearanceData from 'data-hooks/useClearanceData/usePDPClearanceData';
import useUserData from '../useUserData';

import { getOptionAdditionalPrice } from '../useProductPrice/utils';

const usePDPPrice = (id, slug, hookOptions = {}) => {
  const { isTradeUser, userDiscount } = useUserData();

  const pdpSelectedCustomizerTab = usePdpSelectedCustomizerTab();
  const isDecideLaterTab = pdpSelectedCustomizerTab === PDP_DECIDELATER_TAB_ID;

  const { loading: basicLoading, data: basicData } = usePDPBasicDetails() ?? {};
  const { loading: optionsLoading, data: optionsData } = usePDPOptions() ?? {};
  const clearanceHookData = usePDPClearanceData(hookOptions);

  const {
    isClearance = false,
    stockCost = 0,
    clearanceStockPerSKU,
    productCardVariantSKU,
    clearanceProductSku,
  } = clearanceHookData?.data || {};

  const { options = [], selectedOptions = {}, stock } = optionsData || {};

  const isFabricExcludedFromSale = useMemo(() => {
    const selectedFabricOptionValueId =
      selectedOptions?.[FABRIC_PRODUCT_OPTION_ID];

    if (selectedFabricOptionValueId) {
      const fabricOption = options?.length
        ? options.find(o => o.id === FABRIC_PRODUCT_OPTION_ID)
        : null;

      if (fabricOption?.values?.length > 0) {
        const fabricOptionValue = fabricOption.values.find(
          o => o.id === selectedFabricOptionValueId
        );

        return fabricOptionValue?.exclude_from_sale ?? false;
      }
    }

    return false;
  }, [options, selectedOptions]);

  const priceObj = useMemo(() => {
    if (!basicData?.meta?.price) {
      return {};
    }

    const originalPrice = basicData?.meta?.price?.original_price || 0;

    if (isClearance && !isDecideLaterTab) {
      return {
        ...basicData.meta.price,
        final_price: originalPrice + stockCost,
      };
    }

    if (isFabricExcludedFromSale && !isDecideLaterTab) {
      return {
        ...basicData.meta.price,
        discount_percent: isTradeUser ? userDiscount : 0,
        max_discount_percent: isTradeUser ? userDiscount : 0,
        final_price: isTradeUser
          ? Math.round((originalPrice * (100 - userDiscount)) / 100)
          : originalPrice,
      };
    }

    return basicData.meta.price;
  }, [
    basicData,
    isClearance,
    stockCost,
    isDecideLaterTab,
    isFabricExcludedFromSale,
    isTradeUser,
    userDiscount,
  ]);

  const optionPrice = useMemo(
    () =>
      getOptionAdditionalPrice(
        options,
        stock?.stockOptions,
        selectedOptions,
        !isDecideLaterTab,
        optionsData?.upholsteryOptionId
      ),
    [
      options,
      stock?.stockOptions,
      selectedOptions,
      isDecideLaterTab,
      optionsData?.upholsteryOptionId,
    ]
  );

  return {
    loading: optionsLoading || basicLoading,
    data: basicData
      ? {
          price: priceObj,
          promotion: isClearance ? {} : basicData?.meta?.promotion,
          optionPrice,
          isClearance,
          stockCost,
          clearanceStockPerSKU,
          productCardVariantSKU,
          clearanceProductSku,
        }
      : null,
  };
};

export default usePDPPrice;
