import React from 'react';

import ColorsFilter from 'components/essentials/ColorsFilter';
import useProductListingPageContext from 'data-hooks/useProductListingPageContext';

const ProductListingColorFilter = () => {
  const { filters = [], toggleFilterOption } = useProductListingPageContext();

  const colorFilter = filters.find(({ id }) => id === 'color');

  if (!colorFilter) {
    return null;
  }

  const activeColorOptions =
    colorFilter.options
      ?.filter(({ isActive }) => isActive)
      ?.map(({ id }) => id)
      .reduce((acc, color) => ({ ...acc, [color]: true }), {}) ?? {};

  return (
    <ColorsFilter
      colors={colorFilter?.options?.map(option => option.id)}
      onSelectColor={optionId => {
        toggleFilterOption('color', optionId);
      }}
      selectedColors={activeColorOptions}
    />
  );
};

export default ProductListingColorFilter;
