import React from 'react';

import useProductListingPageContext from 'data-hooks/useProductListingPageContext';
import ProductListingPageDescriptionUI from 'components/consumer/ProductListingPageDescriptionUI';

const ProductListingPageDescription = () => {
  const { pageLongDescription, pageName } = useProductListingPageContext();

  return (
    <div className="mx-5 md:mx-0 md:mt-24">
      <ProductListingPageDescriptionUI label={pageName}>
        {pageLongDescription}
      </ProductListingPageDescriptionUI>
    </div>
  );
};

export default ProductListingPageDescription;
