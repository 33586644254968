import { useCallback, useEffect, useState } from 'react';

import useIntervalTimer from 'hooks/useIntervalTimer';

// This hook returns multiple event handlers which can be used to detect when
// the user has performed a medium and long press, firing one callback after a
// short delay and another after a longer delay.

const useMediumAndLongPress = ({
  onClick,
  onMediumPress,
  onLongPress,
  mediumPressDelay = 500,
  longPressDelay = 3000,
}) => {
  const [isTouchDevice, setIsTouchDevice] = useState(false);
  const [startTime, setStartTime] = useState(null);
  const [hasMediumPressed, setHasMediumPressed] = useState(false);
  const [hasLongPressed, setHasLongPressed] = useState(false);

  useEffect(() => {
    if (hasMediumPressed) {
      onMediumPress?.();
    }
  }, [hasMediumPressed, onMediumPress]);

  useEffect(() => {
    if (hasLongPressed) {
      onLongPress?.();
    }
  }, [hasLongPressed, onLongPress]);

  const onStartPressing = useCallback(() => {
    setStartTime(Date.now());
  }, []);

  const onStartPressingTouch = useCallback(() => {
    setIsTouchDevice(true);
    onStartPressing();
  }, [onStartPressing]);

  const onStopPressing = useCallback(() => {
    if (!startTime) {
      return;
    }

    if (Date.now() - startTime < mediumPressDelay) {
      onClick?.();
    }

    setStartTime(null);
    setHasMediumPressed(false);
    setHasLongPressed(false);
  }, [mediumPressDelay, onClick, startTime]);

  useIntervalTimer(
    () => {
      const updatedHasMediumPressed =
        startTime && Date.now() - startTime >= mediumPressDelay;
      const updatedHasLongPressed =
        startTime && Date.now() - startTime >= longPressDelay;

      setHasMediumPressed(updatedHasMediumPressed);
      setHasLongPressed(updatedHasLongPressed);
    },
    // If startTime is falsy, the timer will stop
    startTime ? 50 : null,
    true
  );

  return {
    // To detect button presses on all devices, the following 5 event handlers
    // should all be passed into the element being pressed.
    // Touch events fire first, so if a touch is detected, we should ignore the
    // onMouseDown event. When the touch ends, whichever event fires first will
    // trigger the onStopPressing callback and reset the state.
    onMouseDown: !isTouchDevice ? onStartPressing : undefined,
    onMouseLeave: onStopPressing,
    onMouseUp: onStopPressing,
    onTouchEnd: onStopPressing,
    onTouchStart: onStartPressingTouch,
    // The onMediumPress and onLongPress callbacks are useful for imperative
    // actions such as navigation, while the hasMediumPressed and
    // hasLongPressed boolean is useful for declaratively controlled UI such as
    // styling, where that UI should change after a specified time has passed.
    hasMediumPressed,
    hasLongPressed,
  };
};

export default useMediumAndLongPress;
