import loadable from '@loadable/component';

import isNewConsumerApp from 'commons/isNewConsumerApp';
import Passthrough from 'components/consumer/Passthrough';

const TabletAndDesktopOnlyLegacy = loadable(() =>
  import('./TabletAndDesktopOnlyLegacy')
);

// This component conditionally only renders children on tablet and desktop in
// the legacy consumer app, but in the new app will render at all breakpoints
// as required by React 18.
const TabletAndDesktopOnly = isNewConsumerApp
  ? Passthrough
  : TabletAndDesktopOnlyLegacy;

export default TabletAndDesktopOnly;
