import { useMemo } from 'react';
import { isEmpty } from 'lodash';

import cleanObjectFromEmptyValues from 'commons/cleanObjectFromEmptyValues';
import usePdpId from 'components/consumer/PDP/state/context/usePdpId';
import usePdpSlug from 'components/consumer/PDP/state/context/usePdpSlug';

// Get variables object for any Apollo Client queries made on PDP. If product
// ID is available then only include that variable in the output, otherwise
// only include the slug. If neither slug or ID are available, skip the query.
const usePDPApolloQueryVariables = (idOverride = null, slugOverride = null) => {
  const id = usePdpId();
  const slug = usePdpSlug();

  const variables = useMemo(() => {
    const finalProductId = idOverride || id;

    return cleanObjectFromEmptyValues({
      id: finalProductId,
      slug: finalProductId ? null : slugOverride || slug,
    });
  }, [idOverride, slugOverride, slug, id]);

  const skip = useMemo(() => isEmpty(variables), [variables]);

  return { variables, skip };
};

export default usePDPApolloQueryVariables;
