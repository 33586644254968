import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// This is a styling element to be wrapped around each of the mobile filter
// controls, which adds a faint gray line between each filter
const MobileProductListingFilterWrapper = ({ children, id, label }) => {
  const isQuickshipFilter = id === 'quickship';

  return (
    <div
      className={classNames(
        '[&:last-child]:border-0 [&:last-child]:mb-0 [&:last-child]:pb-0',
        {
          'border-b-[1px] border-gray-light7 mb-5 pb-5': !isQuickshipFilter,
          'border-0 mb-3 pb-0': isQuickshipFilter,
        }
      )}
      data-xc={`mobile-filter-section-${id ?? ''}`}
    >
      {!!label && !isQuickshipFilter && (
        <h3 className="mb-2 text-base font-bold text-gray">{label}</h3>
      )}

      {children}
    </div>
  );
};

MobileProductListingFilterWrapper.propTypes = {
  children: PropTypes.node,
  id: PropTypes.string,
  label: PropTypes.string,
};

export default MobileProductListingFilterWrapper;
