import React from 'react';
import BlockPlaceholder from 'components/consumer/BlockPlaceholder';
import TextPlaceholder from 'components/consumer/TextPlaceholder';

const ProductCardStaticPlaceholder = () => (
  <div className="relative flex flex-col">
    <div className="aspect-square">
      <BlockPlaceholder className="!w-full !h-full" />
    </div>

    <TextPlaceholder className="!w-1/2 !h-5" />
    <TextPlaceholder className="!w-1/5 !h-[15px]" />
  </div>
);

export default ProductCardStaticPlaceholder;
