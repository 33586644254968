import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import FixedWidthContainer from 'components/consumer/FixedWidthContainer';
import BrowserOnly from 'components/consumer/BrowserOnly';
import ProductListingPageContext from 'data-hooks/context/ProductListingPageContext';
import ProductListingPageWidgets from 'components/consumer/ProductListingPageWidgets';
import isNewConsumerApp from 'commons/isNewConsumerApp';
import ProductListingPageDescription from './components/ProductListingPageDescription';
import DesktopProductListingSidebarFilters from './components/desktop/DesktopProductListingSidebarFilters';
import ProductListingSortBy from './components/ProductListingSortBy';
import DesktopShowHideFiltersButton from './components/desktop/DesktopShowHideFiltersButton';
import ProductListingResultsCount from './components/ProductListingResultsCount';
import DesktopProductListingSidebarContainer from './components/desktop/DesktopProductListingSidebarContainer';
import ProductListingLoadMoreButtons from './components/ProductListingLoadMoreButtons';
import ProductListingGrid from './components/ProductListingGrid';
import ProductListingPageName from './components/ProductListingPageName';
import useProductListingAnalytics from './hooks/useProductListingAnalytics';
import ProductListingPageTitleAndMetaTags from './components/ProductListingPageTitleAndMetaTags';
import MobileGridColumnToggle from './components/mobile/MobileGridColumnToggle';
import MobileProductListingFiltersButtonAndModal from './components/mobile/MobileProductListingFiltersButtonAndModal';
import DesktopProductListingColorFilter from './components/desktop/DesktopProductListingColorFilter';
import ProductListingPagePlaceholder from './ProductListingPagePlaceholder';

const ProductListingPage = ({
  pageData,
  sidebarHeader,
  pageHeaderOverride,
  variant,
}) => {
  const [isDesktopSidebarOpen, setIsDesktopSidebarOpen] = useState(true);

  useProductListingAnalytics(pageData);

  const {
    loading,
    isFinalProductCountKnown,
    pageName,
    totalProductCount,
  } = pageData;

  if (isNewConsumerApp && loading) {
    return <ProductListingPagePlaceholder />;
  }

  // We expose pageData via context so that nested components can access it
  // without multiple levels of prop drilling
  return (
    <ProductListingPageContext.Provider value={pageData}>
      <ProductListingPageTitleAndMetaTags />

      <FixedWidthContainer
        wide
        noMobilePadding
        noPadding={variant === 'advanced-search'}
      >
        <div
          className={classNames(
            'px-3 py-4 space-y-1',
            'md:px-0 md:py-0 md:space-y-0',
            'md:items-center md:bg-white md:flex md:h-[53px] md:sticky md:top-[55px] md:z-[11] md:gap-5 md:justify-between'
          )}
        >
          <div className="md:flex md:gap-5 md:items-center">
            <ProductListingPageName
              loading={loading}
              pageName={pageName}
              showPageNameWhileLoading={variant === 'advanced-search'}
            />

            <ProductListingResultsCount
              loading={loading || !isFinalProductCountKnown}
              totalProductCount={totalProductCount}
            />

            <DesktopShowHideFiltersButton
              loading={loading}
              onClick={() => {
                setIsDesktopSidebarOpen(prevIsOpen => !prevIsOpen);
              }}
            />
          </div>

          <div
            className={classNames(
              'flex flex-row justify-between gap-3 py-3',
              'md:block md:gap-0 md:py-0'
            )}
          >
            <div
              className={classNames(
                'flex-1 flex gap-1',
                'md:flex-initial md:block md:gap-0'
              )}
            >
              <MobileProductListingFiltersButtonAndModal />

              <div className="flex-1 md:flex-initial md:flex md:flex-row md:gap-4 md:items-center">
                {pageHeaderOverride ?? (
                  <>
                    <DesktopProductListingColorFilter />
                    <ProductListingSortBy />
                  </>
                )}
              </div>
            </div>

            <MobileGridColumnToggle />
          </div>
        </div>

        <div className="md:flex md:flex-row md:gap md:mt-2">
          <div className="hidden md:block">
            <DesktopProductListingSidebarContainer
              isOpen={isDesktopSidebarOpen}
            >
              {sidebarHeader}
              <DesktopProductListingSidebarFilters />
            </DesktopProductListingSidebarContainer>
          </div>

          <div className="md:space-y-8 md:mb-8 md:flex-1">
            <ProductListingGrid />

            <BrowserOnly>
              <ProductListingLoadMoreButtons />
            </BrowserOnly>

            <ProductListingPageDescription />
          </div>
        </div>
      </FixedWidthContainer>

      <ProductListingPageWidgets categoryId={pageData?.pageId} />
    </ProductListingPageContext.Provider>
  );
};

ProductListingPage.propTypes = {
  pageData: PropTypes.shape({
    filters: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        options: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string,
            label: PropTypes.string,
          })
        ),
      })
    ),
    filterStateKey: PropTypes.string,
    loading: PropTypes.bool,
    loadMoreClickCount: PropTypes.number,
    loadMoreProducts: PropTypes.func,
    isAllDataLoading: PropTypes.bool,
    isFinalProductCountKnown: PropTypes.bool,
    pageId: PropTypes.number,
    pageName: PropTypes.string,
    pageLongDescription: PropTypes.string,
    products: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        initialOptionId: PropTypes.string,
        name: PropTypes.string,
        onDisplayInShowroomSlug: PropTypes.string,
        path: PropTypes.string,
        productLabel: PropTypes.string,
        quickship: PropTypes.bool,
        secondaryImage: PropTypes.string,
        secondaryImageCropData: PropTypes.object,
        variants: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string,
            image: PropTypes.string,
            minPrice: PropTypes.number,
            originalPrice: PropTypes.number,
            path: PropTypes.string.isRequired,
            price: PropTypes.number.isRequired,
            quickship: PropTypes.bool,
          })
        ),
        variantsTotal: PropTypes.number,
      })
    ),
    resetFilters: PropTypes.func,
    setSortOrder: PropTypes.func,
    showLoadMoreButton: PropTypes.bool,
    sortOrder: PropTypes.string,
    toggleFilter: PropTypes.func,
    toggleFilterOption: PropTypes.func,
    totalProductCount: PropTypes.number,
  }),
  sidebarHeader: PropTypes.node,
  pageHeaderOverride: PropTypes.node,
  variant: PropTypes.oneOf(['advanced-search']),
};

export default ProductListingPage;
