import React from 'react';
import PropTypes from 'prop-types';

import ProductListingQuickshipFilter from '../../ProductListingQuickshipFilter';
import MobileProductListingFilterWrapper from './MobileProductListingFilterWrapper';
import ProductListingColorFilter from '../../ProductListingColorFilter';
import MobileProductListingListFilter from './MobileProductListingListFilter';
import ProductListingWidthRangeFilter from '../../ProductListingWidthRangeFilter';

const MobileProductListingFilter = ({ id, label }) => (
  <MobileProductListingFilterWrapper id={id} label={label}>
    {(() => {
      if (id === 'quickship') {
        return <ProductListingQuickshipFilter />;
      }

      if (id === 'color') {
        return <ProductListingColorFilter />;
      }

      if (id === 'widthRange') {
        return <ProductListingWidthRangeFilter />;
      }

      return <MobileProductListingListFilter id={id} />;
    })()}
  </MobileProductListingFilterWrapper>
);

MobileProductListingFilter.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
};

export default MobileProductListingFilter;
