import React from 'react';

import { NUMBER_OF_PRODUCTS_TO_SHOW } from 'commons/constants';
import useProductListingPageContext from 'data-hooks/useProductListingPageContext';
import ProductCardGridUI from 'components/consumer/ProductCardGridUI';
import ProductCardStatic from 'components/consumer/ProductCardStatic';
import ProductCardStaticPlaceholderGroup from 'components/consumer/ProductCardStaticPlaceholderGroup';
import useMobileColumnCount from '../state/mobileColumnCount/useMobileColumnCount';

const ProductListingGrid = () => {
  const {
    filterStateKey,
    isAllDataLoading,
    isFinalProductCountKnown,
    loading,
    products,
    productsOnDisplay,
    productsToPreload,
    layout,
  } = useProductListingPageContext();

  const mobileColumnCount = useMobileColumnCount();

  const skeletonLoaderItemsCount =
    products?.length > 0 && products?.length < NUMBER_OF_PRODUCTS_TO_SHOW
      ? NUMBER_OF_PRODUCTS_TO_SHOW - products.length
      : NUMBER_OF_PRODUCTS_TO_SHOW;

  const allProductsToShow = [
    ...products,
    ...productsToPreload.map(product => ({
      ...product,
      isHidden: true,
    })),
  ];

  return (
    <ProductCardGridUI
      limitWidth={false}
      columnsOnDesktop="auto"
      columnsOnMobile={mobileColumnCount}
    >
      {loading && (
        <ProductCardStaticPlaceholderGroup count={skeletonLoaderItemsCount} />
      )}

      {!loading &&
        allProductsToShow?.map(
          ({
            campaign,
            id,
            isClearance,
            isFavorited,
            isHidden,
            label,
            moreVariantsCount,
            name,
            onDisplayInShowroomSlug,
            path,
            position,
            productCardKey,
            productFamily,
            productLabel,
            productType,
            quickship,
            secondaryImage,
            secondaryImageCropData,
            slug,
            variants,
            variantsToShow,
            variantsTotal,
          }) => (
            <ProductCardStatic
              campaign={campaign}
              enableMobileCarousel
              filterStateKey={filterStateKey}
              hidden={isHidden}
              id={id}
              isClearance={isClearance}
              isFavorited={isFavorited}
              key={productCardKey}
              label={label}
              layout={layout}
              moreVariantsCount={moreVariantsCount}
              name={name}
              onDisplayInShowroomSlug={onDisplayInShowroomSlug}
              path={path}
              position={position}
              productFamily={productFamily}
              productLabel={productLabel}
              productsOnDisplay={productsOnDisplay}
              productType={productType}
              quickship={quickship}
              secondaryImage={secondaryImage}
              secondaryImageCropData={secondaryImageCropData}
              slug={slug}
              variants={variants}
              variantsToShow={variantsToShow}
              variantsTotal={variantsTotal}
            />
          )
        )}

      {!loading &&
        isAllDataLoading &&
        filterStateKey !== 'no-filters--featured' && (
          <ProductCardStaticPlaceholderGroup count={skeletonLoaderItemsCount} />
        )}

      {isFinalProductCountKnown && !products?.length && (
        <div className="p-2 font-bold">No results.</div>
      )}
    </ProductCardGridUI>
  );
};

export default ProductListingGrid;
