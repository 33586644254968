import React from 'react';
import PropTypes from 'prop-types';

import CtaButton from 'components/essentials/Cta/CtaButton';
import isClient from 'commons/isClient';
import SanitizedHtml from 'components/consumer/SanitizedHtml';

const SHOW_MORE_LIMIT = 500;

class ExpandableTextContainer extends React.Component {
  state = {
    content: this.props.content,
    showMoreEnabled: this.props.content?.length > SHOW_MORE_LIMIT,
    showMoreExpanded: false,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.content && nextProps.content !== prevState.content) {
      return {
        content: nextProps.content,
        showMoreEnabled: nextProps.content?.length > SHOW_MORE_LIMIT,
        showMoreExpanded: false,
      };
    }
    return null;
  }

  getContent = () => {
    if (!this.state.showMoreEnabled) {
      return this.state.content;
    }
    return this.state.showMoreExpanded
      ? this.state.content
      : `${this.state.content.substring(0, SHOW_MORE_LIMIT)} ...`;
  };

  toggleShowMore = () => {
    this.setState(prevState => ({
      showMoreExpanded: !prevState.showMoreExpanded,
    }));
  };

  render() {
    return (
      <div className="[&_a]:text-brand [&_button]:[background:none] [&_button]:border-0">
        <SanitizedHtml
          as="button"
          type="button"
          label="expandable-text-container-button"
          className="bg-none border-0"
          onClick={evt => {
            // https://stitchindustries.atlassian.net/browse/CON-3001
            if (isClient() && evt.target.tagName.toLowerCase() === 'a') {
              window.sessionStorage.removeItem('scrollPosition.category');
            }
          }}
          html={this.getContent()}
        />

        {this.state.showMoreEnabled && (
          <CtaButton
            styling="link"
            hasArrow={false}
            color="brand"
            onClick={this.toggleShowMore}
            className="bg-none border-0"
          >
            {this.state.showMoreExpanded ? 'Read less' : 'Read more'}
          </CtaButton>
        )}
      </div>
    );
  }
}

ExpandableTextContainer.propTypes = {
  content: PropTypes.string.isRequired,
};

export default ExpandableTextContainer;
