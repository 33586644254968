import React, { useState } from 'react';

import MobileProductListingFiltersButton from './MobileProductListingFiltersButton';
import MobileProductListingFiltersModal from './MobileProductListingFiltersModal';

const MobileProductListingFiltersButtonAndModal = () => {
  const [isMobileFiltersModalOpen, setIsMobileFiltersModalOpen] = useState(
    false
  );

  return (
    <>
      <MobileProductListingFiltersButton
        onClick={() => {
          setIsMobileFiltersModalOpen(true);
        }}
      />

      <MobileProductListingFiltersModal
        isOpen={isMobileFiltersModalOpen}
        onClose={() => {
          setIsMobileFiltersModalOpen(false);
        }}
      />
    </>
  );
};

export default MobileProductListingFiltersButtonAndModal;
