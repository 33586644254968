import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { RemoveScroll } from 'react-remove-scroll';
import { Modal as ReactResponsiveModal } from 'react-responsive-modal';

import IconClose from 'components/icons/iconClose';

import 'react-responsive-modal/styles.css';

const modalCloseIcon = (
  <div className="items-center flex justify-center h-[35px] w-[35px]">
    <IconClose color="#ffffff" height={21} width={21} />
  </div>
);

const Modal = ({ open, ...props }) => {
  const container = useMemo(
    () => document.querySelector('#react-responsive-modal-root'),
    []
  );

  return (
    <RemoveScroll enabled={open} removeScrollBar={false}>
      <ReactResponsiveModal
        blockScroll={false}
        closeIcon={modalCloseIcon}
        container={container}
        open={open}
        {...props}
      />
    </RemoveScroll>
  );
};

Modal.propTypes = {
  open: PropTypes.bool,
};

export default Modal;
