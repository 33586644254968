const shortPageNameMapping = {
  'Decoration Accessories': 'Decor',
  'Sofas & Sectionals': 'Sofas',
};

// Horizontal space available for placeholder text in the desktop search filter
// is very limited so we use this function to map the full names to short names.
const getShortPageName = pageName => {
  const shortName = shortPageNameMapping[pageName];

  // For many of the categories, it is enough to just remove the " Furniture"
  // suffix from the page name. E.g. "Living Room Furniture" -> "Living Room".
  return shortName ?? pageName.replace(' Furniture', '');
};

export default getShortPageName;
