import { isEmpty } from 'lodash';

import useSuspenseQuery from 'commons/useSuspenseQuery';
import getVisitorLocationQueryVariables from 'commons/getVisitorLocationQueryVariables';
import useUserLocation from 'global-state/userLocation/useUserLocation';
import usePDPApolloQueryVariables from '../usePDPApolloQueryVariables';
import { PRODUCT_BASIC_DETAILS } from '../queries';

const getNonPDPQueryVariables = (id, productSku, hookOptions = {}) => {
  const { skip = false, defaultValue = {} } = hookOptions;

  if (skip) {
    return defaultValue;
  }

  if (id) {
    return { id };
  }

  if (productSku) {
    return { productSku };
  }

  return {};
};

const useProductBasicDetails = (
  id,
  slug,
  queryOptions = {},
  hookOptions = {
    skip: false,
  }
) => {
  const {
    skipPDPStateHook = false,
    delayLoad = false,
    productSku = '',
    regionId,
  } = queryOptions;

  const { skip = false } = hookOptions;

  const finalNonPDPQueryVariables = skipPDPStateHook
    ? getNonPDPQueryVariables(id, productSku, hookOptions)
    : {};

  const userLocation = useUserLocation();

  const locationVars = getVisitorLocationQueryVariables(
    userLocation?.stateShortName,
    regionId
  );
  const { variables, skip: skipPdpQuery } = usePDPApolloQueryVariables(
    id,
    slug,
    {
      skip: skipPDPStateHook,
    }
  );
  const skipPdpVariableQuery =
    productSku && skipPdpQuery ? false : skipPdpQuery;

  const { loading, error, data, refetch } = useSuspenseQuery(
    PRODUCT_BASIC_DETAILS,
    {
      variables: skipPDPStateHook
        ? { ...finalNonPDPQueryVariables, ...locationVars }
        : { ...variables, ...locationVars },
      skip:
        skip ||
        skipPdpVariableQuery ||
        delayLoad ||
        (skipPDPStateHook && isEmpty(finalNonPDPQueryVariables)),
      context: { noBatch: true },
      ssr: queryOptions?.ssr ?? true,
    }
  );

  return {
    loading: loading || skipPdpVariableQuery || !data,
    error,
    data: data?.basicDetails,
    rawLoading: loading,
    rawData: data,
    refetch,
  };
};

export default useProductBasicDetails;
