import React from 'react';
import PropTypes from 'prop-types';

import ProductCardStaticPlaceholder from '../ProductCardStaticPlaceholder';

// This component allows us to render a group of static product card
// placeholders, without having to manually map over an array and add an
// exception for the ESLint react/no-array-index-key rule every time.
const ProductCardStaticPlaceholderGroup = ({ count = 12 }) => (
  <>
    {Array.from(Array(count)).map((item, index) => (
      // It's okay to use index as key in this component because it only renders
      // static placeholder elements
      // eslint-disable-next-line react/no-array-index-key
      <ProductCardStaticPlaceholder key={index} />
    ))}
  </>
);

ProductCardStaticPlaceholderGroup.propTypes = {
  count: PropTypes.number,
};

export default ProductCardStaticPlaceholderGroup;
