import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { usePrevious } from 'react-use';
import classNames from 'classnames';
import { GET_CART } from 'mocks/queries';

import useToggleCart from 'global-state/isCartOpen/useToggleCart';
import getZipcode from 'commons/getZipcode';
import useSuspenseQuery from 'commons/useSuspenseQuery';

const IcCart = ({ className = null, isFixedTopNav = false, isMobile }) => {
  const toggleCart = useToggleCart();

  const getCart = useSuspenseQuery(GET_CART, {
    variables: { zipCode: getZipcode() },
  });

  let cartSize = 0;
  const cart = get(getCart, 'data.getCart.cart');

  if (cart && cart.length) {
    cartSize = cart.reduce((acc, cur) => acc + (cur.quantity || 0), 0);
  }

  const prevCartSize = usePrevious(cartSize);

  const [isCountUpdated, setIsCountUpdated] = useState(false);

  useEffect(() => {
    if (prevCartSize < cartSize) {
      setIsCountUpdated(true);
      setTimeout(() => {
        setIsCountUpdated(false);
      }, 600);
    }
  }, [cartSize, getCart, prevCartSize]);

  return isMobile ? (
    <button
      type="button"
      className={classNames('[background:none] border-0', {
        [className]: className,
        'cursor-pointer bg-white rounded-[2rem] box-border w-12 h-12 flex justify-center items-center -mr-2.5 relative': isFixedTopNav,
        'hover:bg-gray-light7': isFixedTopNav,
        'active:bg-gray-light7': isFixedTopNav,
        'focus-visible:bg-gray-light7': isFixedTopNav,
      })}
      onClick={toggleCart}
    >
      {!!cartSize && (
        <span className="text-xs inline-block text-center text-white font-bold absolute w-[15px] h-[15px] bg-brand rounded-sm bottom-2.5 right-3">
          {cartSize}
        </span>
      )}

      <svg
        width="18"
        height="19"
        viewBox="0 0 18 19"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(-3 -2)" stroke="#585858" strokeWidth="1.5">
            <g transform="translate(3 3)">
              <g>
                <path d="M13.5,4.42285714 C13.5,1.98018059 11.4852814,0 9,0 C6.51471863,0 4.5,1.98018059 4.5,4.42285714" />
                <rect x="0.75" y="4.125" width="16.5" height="13.125" rx="2" />
              </g>
            </g>
          </g>
        </g>
      </svg>
    </button>
  ) : (
    <button
      type="button"
      className={classNames(
        'relative cursor-pointer bg-white h-[42px] w-[42px] rounded-full flex justify-center items-center ml-[.4rem] border-2 border-solid border-transparent [&_svg_g]:stroke-[#424242] [&_svg_path]:stroke-[#424242]',
        { [className]: className }
      )}
      onClick={toggleCart}
      aria-label="Open Cart dialog"
    >
      <span className="flex items-center [&_svg_path]:hover:stroke-[#107c8c] [&_svg_rect]:hover:stroke-[#107c8c]">
        {!!cartSize && (
          <span
            className={`text-xs inline-block text-center text-white font-bold absolute right-[5px] bottom-[7px] w-[15px] h-[15px] bg-brand rounded-sm ${
              isCountUpdated ? 'updated' : ''
            }`}
          >
            {cartSize}
          </span>
        )}
        <svg
          width="18"
          height="19"
          viewBox="0 0 18 19"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g fill="none" fillRule="evenodd">
            <g transform="translate(-3 -2)" stroke="#484848" strokeWidth="1.5">
              <g transform="translate(3 3)">
                <g>
                  <path d="M13.5,4.42285714 C13.5,1.98018059 11.4852814,0 9,0 C6.51471863,0 4.5,1.98018059 4.5,4.42285714" />
                  <rect
                    x="0.75"
                    y="4.125"
                    width="16.5"
                    height="13.125"
                    rx="2"
                  />
                </g>
              </g>
            </g>
          </g>
        </svg>
      </span>
    </button>
  );
};

IcCart.propTypes = {
  className: PropTypes.string,
  isFixedTopNav: PropTypes.bool,
  isMobile: PropTypes.bool,
};

export default IcCart;
