import React from 'react';

import TextPlaceholder from 'components/consumer/TextPlaceholder';
import Placeholder from 'components/consumer/Placeholder';

const ColorFilterPlaceholder = () => (
  <Placeholder
    type="round"
    loading
    className="!w-[40px] !h-[40px] md:!w-[20px] md:!h-[20px]"
  />
);

const ColorFiltersPlaceholder = () => (
  <div className="flex flex-row space-x-1 items-center">
    {[0, 1, 2, 3, 4, 5, 6, 7, 8].map((_, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <ColorFilterPlaceholder key={index} />
    ))}
  </div>
);

const ProductCardFabricSwatchesPlaceholder = () => (
  <div className="flex flex-row max-[767px]:hidden mt-[.4rem] mb-2 justify-between">
    {[0, 1, 2, 3, 4, 5, 6].map((_, index) => (
      <Placeholder
        // eslint-disable-next-line react/no-array-index-key
        key={index}
        loading
        type="rect"
        className="!w-[32px] !h-[32px] rounded aspect-square m-0"
      />
    ))}
  </div>
);

const ProductCardPlaceholder = ({ swatches = true }) => (
  <div className="rounded-sm relative text-gray pb-2 md:pb-0 flex flex-col items-start flex-1 overflow-hidden m-0">
    <Placeholder
      loading
      type="rect"
      className="min-w-[100px] max-w-[400px] min-h-[100px] max-h-[400px] aspect-square"
    />
    <div className="flex flex-col h-[4.6rem]">
      <Placeholder
        loading
        type="text"
        className="!w-[116px] !h-[24px] mt-2 mx-0 mb-0 lg:mt-3.5 xl:mb-0.5"
      />
      <Placeholder loading type="text" className="!w-[58px] !h-[24px]" />
    </div>
    {swatches && <ProductCardFabricSwatchesPlaceholder />}
  </div>
);

const ProductListingGridPlaceholder = () => (
  <div className="w-full flex flex-col">
    <div className="md:mb-8 md:flex-1 grid gap-1 md:gap-4 xl:gap-5 min-[1920px]:gap-8 lg:[grid-template-columns:repeat(auto-fill,_minmax(280px,_1fr))] [grid-template-columns:repeat(2,_minmax(0,_1fr))]">
      {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((_, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <ProductCardPlaceholder key={index} />
      ))}
    </div>
    <div className="flex flex-col items-center">
      <Placeholder loading type="rect" className="!w-[140px] !h-[52px] mt-8" />
      <Placeholder
        loading
        type="rect"
        className="!w-[160px] !h-[24px] mt-24 mb-8"
      />
      <Placeholder loading type="rect" className="!w-[260px] !h-[24px] my-6" />
      <Placeholder
        loading
        type="text"
        rows={4}
        className="max-w-[736px] flex flex-col items-center mb-24"
      />
    </div>
  </div>
);

const ProductListingSidebarFilterPlaceholder = ({ checkboxQuantity }) => (
  <div className="flex flex-col items-start gap-4">
    <Placeholder loading type="rect" className="!w-1/2 !h-[24px]" />
    {Array.from(Array(checkboxQuantity)).map((_, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <span key={index} className="flex flex-row items-center justify-start">
        <Placeholder
          loading
          type="rect"
          className="!w-[20px] !h-[20px] rounded-sm"
        />
        <Placeholder
          loading
          type="rect"
          className="!w-[108px] !h-[20px] ml-3"
        />
      </span>
    ))}
  </div>
);

const ProductListingSidebarPlaceholder = () => (
  <div className="hidden md:block  sticky overflow-x-hidden transition-[all_0.22s_ease-in-out] max-h-full lg:max-h-[calc(100vh_-_3.75rem_-_53px)] top-[20px] lg:top-[calc(3.75rem_+_53px)] self-start opacity-1 w-[140px] min-w-[140px] lg:w-[240px] lg:min-w-[240px] mr-6 pb-4">
    <div className=" flex flex-col gap-5">
      <Placeholder loading type="rect" className="!w-[216px] !h-[56px]" />
      <div className="flex flex-row justify-between">
        <Placeholder
          loading
          type="rect"
          className="!w-[36px] !h-[24px] rounded-full"
        />
        <Placeholder loading type="rect" className="!w-[164px] !h-[24px]" />
      </div>
      <ProductListingSidebarFilterPlaceholder checkboxQuantity={4} />
      <ProductListingSidebarFilterPlaceholder checkboxQuantity={8} />
      <ProductListingSidebarFilterPlaceholder checkboxQuantity={3} />
    </div>
  </div>
);

const ProductListingSidebarAndGridPlaceholder = () => (
  <div className="md:flex md:flex-row md:gap md:mt-2">
    <ProductListingSidebarPlaceholder />
    <ProductListingGridPlaceholder />
  </div>
);

const PLPTopBarPLaceholder = () => (
  <div className="flex justify-between">
    <div className="md:flex md:gap-5 md:items-center mx-2">
      <TextPlaceholder className="!w-[250px] !h-7" />{' '}
      <TextPlaceholder className="!w-[75px] !h-[15px]" />
      <Placeholder
        loading
        type="round"
        className="hidden md:flex !w-[32px] !h-[32px] mt-2"
      />
      <div className="flex flex-row gap-2 my-2 justify-start md:hidden">
        <Placeholder loading type="rect" className="!w-[84px] !h-[48px]" />
        <Placeholder loading type="rect" className="!w-[255px] !h-[48px]" />
      </div>
    </div>
    <div className="hidden md:flex gap-4">
      <ColorFiltersPlaceholder />
      <Placeholder loading type="rect" className="!w-[255px] !h-[36px]" />
    </div>
  </div>
);

const RecentlyViewedCarouselPlaceholder = () => (
  <div className="flex flex-col mt-[144px] mx-2">
    <Placeholder loading type="rect" className="!w-[200px] !h-[24px] mb-6" />
    <div className="flex flex-row justify-between max-w-[1800px] gap-3 overflow-hidden">
      {[0, 1, 2, 3].map((_, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <ProductCardPlaceholder key={index} swatches={false} />
      ))}
    </div>
  </div>
);

const ProductListingPagePlaceholder = () => (
  <div className="mx-auto my-0 py-0 px-0 md:px-[3.333vw] min-[1500px]:px-[50px] max-w-[1800px]">
    <PLPTopBarPLaceholder />
    <ProductListingSidebarAndGridPlaceholder />
    <RecentlyViewedCarouselPlaceholder />
  </div>
);

export default ProductListingPagePlaceholder;
