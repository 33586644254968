import { useState } from 'react';
import { useInterval } from 'react-use';

const useIntervalTimer = (
  callback = () => {},
  delay = 1000,
  isTimerRunningInitial = false
) => {
  const [isTimerRunning, setIsTimerRunning] = useState(isTimerRunningInitial);

  const startTimer = () => {
    setIsTimerRunning(true);
  };

  const stopTimer = () => {
    setIsTimerRunning(false);
  };

  // When delay is set to null, the timer will stop
  useInterval(callback, isTimerRunning ? delay : null);

  return {
    startTimer,
    stopTimer,
  };
};

export default useIntervalTimer;
