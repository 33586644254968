import { useEffect, useState } from 'react';
import avo from 'analytics/avo';
import useDebouncedValue from 'hooks/useDebouncedValue';
import { getFiltersInAvoFormat, getSortInAvoFormat } from '../utils';

const useProductListingAnalytics = pageData => {
  const {
    pageId,
    pageName,
    filters = [],
    sortOrder = [],
    products = [],
    loading,
    filterStateKey,
  } = pageData;
  const [listViewed, setListViewed] = useState(false);
  const [, setLastTrackedFilterState] = useState('no-filters--featured');
  const debouncedFilterStateKey = useDebouncedValue(filterStateKey, 500);

  useEffect(() => {
    if (!loading && !listViewed) {
      setListViewed(true);

      try {
        if (!Number.isNaN(pageId)) {
          avo.productListViewed({
            categoryId: pageId,
            listId: pageName,
            category: pageName,
            filters: getFiltersInAvoFormat(filters) || [''],
            products: products.map(product => {
              const { variantsToShow = [] } = product;
              const firstVariantShown = variantsToShow?.[0];

              return {
                category: pageName,
                name: product.name,
                shippingCarrier: 'Custom',
                shippingMethod: 'Curbside',
                currency: 'USD',
                price: parseFloat(firstVariantShown.price),
                fullPrice: parseFloat(firstVariantShown.originalPrice),
                productId: product.id,
                quantity: 1,
                sku: firstVariantShown.sku,
                isFlashSale: !!firstVariantShown.isFlashSale,
              };
            }),
          });
        }
      } catch {
        // Ignore error if avo is not initialized
      }
    }
  }, [filters, listViewed, loading, pageId, pageName, products]);

  useEffect(() => {
    if (!pageName || debouncedFilterStateKey === null) {
      return;
    }

    setLastTrackedFilterState(prevLastTrackedFilterState => {
      if (debouncedFilterStateKey === prevLastTrackedFilterState) {
        return prevLastTrackedFilterState;
      }

      try {
        avo.productListFiltered({
          categoryId: pageId,
          listId: pageName,
          category: pageName,
          filters: getFiltersInAvoFormat(filters) || [''],
          sorts: getSortInAvoFormat(sortOrder) || [''],
          products: products.map(product => {
            const { variantsToShow = [] } = product;
            const firstVariantShown = variantsToShow?.[0];

            return {
              category: pageName,
              name: product.name,
              currency: 'USD',
              price: parseFloat(firstVariantShown.price),
              fullPrice: parseFloat(firstVariantShown.originalPrice),
              productId: product.id,
              quantity: 1,
              sku: firstVariantShown.sku,
              isFlashSale: !!firstVariantShown?.isFlashSale,
            };
          }),
        });
      } catch {
        // Ignore error if avo is not initialized
      }

      return filterStateKey;
    });
  }, [
    pageName,
    filters,
    products,
    loading,
    pageId,
    sortOrder,
    debouncedFilterStateKey,
    filterStateKey,
  ]);
};
export default useProductListingAnalytics;
