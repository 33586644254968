/* eslint-disable no-unused-vars */
import { getUserFromCookie } from 'commons/localStorage';
import isServer from 'commons/isServer';

const RudderstackAvoDestination = {
  make(env) {
    if (isServer() || !window.rudderanalytics) {
      return;
    }

    if (getUserFromCookie()?.id) {
      window.rudderanalytics.identify(getUserFromCookie().id, {
        email: getUserFromCookie().email,
      });
    }
  },
  logEvent(eventName, eventProperties) {
    if (isServer() || !window.rudderanalytics) {
      return;
    }

    window.rudderanalytics.track(eventName, eventProperties);
  },
  setUserProperties(userId, userProperties) {
    if (isServer() || !window.rudderanalytics) {
      return;
    }

    window.rudderanalytics.identify(userId, userProperties);
  },
  identify(userId) {
    if (isServer() || !window.rudderanalytics) {
      return;
    }

    window.rudderanalytics.identify(userId);
  },
  unidentify() {
    if (isServer() || !window.rudderanalytics) {
      return;
    }

    window.rudderanalytics.reset();
  },
  revenue(amount, eventProperties) {},
  page(pageName, eventProperties) {
    if (isServer() || !window.rudderanalytics) {
      return;
    }

    window.rudderanalytics.page({
      properties: { url: window.location.href, title: document.title },
    });
  },
};

export default RudderstackAvoDestination;
