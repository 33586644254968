import React from 'react';
import { Helmet } from 'react-helmet-async';

import useProductListingPageContext from 'data-hooks/useProductListingPageContext';
import ProductListingSEOLinkedData from './ProductListingSEOLinkedData';

const ProductListingPageTitleAndMetaTags = () => {
  const {
    loading,
    pageHtmlTitle = '',
    pageMetaDescription = '',
    pageName = '',
  } = useProductListingPageContext();

  return (
    <>
      <Helmet>
        <title>{pageHtmlTitle}</title>
        <meta name="description" content={pageMetaDescription} />
        <meta property="og:type" content="category" />
        <meta property="og:title" content={pageName} />
        <meta property="og:description" content={pageMetaDescription} />
      </Helmet>

      {!loading && <ProductListingSEOLinkedData />}
    </>
  );
};

export default ProductListingPageTitleAndMetaTags;
