import React from 'react';
import PropTypes from 'prop-types';

import ExpandableTextContainer from 'components/essentials/ExpandableTextContainer';

const ProductListingPageDescriptionUI = ({ label = '', children }) => (
  <div className="mx-auto my-24 max-w-[736px]">
    <h2 className="relative text-lg font-bold text-gray pb-6 mb-2 text-center after:content-[''] after:block after:w-28 after:h-px after:bg-gray-light7 after:absolute after:bottom-0 after:left-1/2 after:-ml-14">
      {label}
    </h2>

    <div className="text-sm leading-6 tracking-[0.2px] text-gray mt-6 text-center [&_a]:text-brand [&_li]:list-none">
      <ExpandableTextContainer content={children || ''} />
    </div>
  </div>
);

ProductListingPageDescriptionUI.propTypes = {
  children: PropTypes.node,
  label: PropTypes.string,
};

export default ProductListingPageDescriptionUI;
