import useCustomEvent from 'hooks/useCustomEvent';
import AllOptions, { ALL_OPTION_UPDATED_EVENT_TYPE } from './AllOptions';

const useAllOptionsOld = (skip = false) => {
  const [
    allOptionsLoading,
    allOptionsValues,
    allOptionsByValuesKey,
  ] = AllOptions.getAllOptions(skip);

  // This is needed to make this hook update whenever the options are updated in AllOptions module.
  useCustomEvent({
    type: ALL_OPTION_UPDATED_EVENT_TYPE,
  });

  return {
    loading: allOptionsLoading,
    error: null,
    allOptionsValues,
    allOptionsByValuesKey,
  };
};

export default useAllOptionsOld;
