import React from 'react';

import useProductListingPageContext from 'data-hooks/useProductListingPageContext';
import LabeledSelectBox from 'components/consumer/LabeledSelectBox';
import { SORT_BY_OPTIONS } from 'commons/constants';
import MobileOnly from 'components/consumer/MobileOnly';
import TabletAndDesktopOnly from 'components/consumer/TabletAndDesktopOnly';

const ProductListingSortBy = () => {
  const { setSortOrder, sortOrder } = useProductListingPageContext();

  return (
    <div className="max-w-[215px] md:min-w-[16rem]">
      <MobileOnly>
        <LabeledSelectBox
          className="md:hidden"
          label="Sort by"
          options={SORT_BY_OPTIONS}
          value={sortOrder}
          showBlankOption={false}
          showSingleLineLabelOverlay
          noMargin
          size="xs"
          onChange={e => {
            const updatedValue = e?.target?.value;
            setSortOrder(updatedValue);
          }}
        />
      </MobileOnly>

      <TabletAndDesktopOnly>
        <LabeledSelectBox
          className="max-[767px]:hidden"
          label="Sort by"
          options={SORT_BY_OPTIONS}
          value={sortOrder}
          showBlankOption={false}
          showSingleLineLabelOverlay
          noMargin
          size="sm"
          onChange={e => {
            const updatedValue = e?.target?.value;
            setSortOrder(updatedValue);
          }}
        />
      </TabletAndDesktopOnly>
    </div>
  );
};

export default ProductListingSortBy;
