import React, { useState, useMemo, useEffect } from 'react';
import LabeledTextInput from 'components/consumer/LabeledTextInput';
import ValidationError from 'components/consumer/ValidationError';
import useProductListingPageContext from 'data-hooks/useProductListingPageContext';
import useDebouncedValue from 'hooks/useDebouncedValue';
import { usePrevious } from 'react-use';
import PropTypes from 'prop-types';

const ProductListingWidthRangeFilter = ({ showTitle = false }) => {
  const { filters = [], setCustomFilter } = useProductListingPageContext();

  const { minAllowed, maxAllowed, value: valueInPageState } = filters.find(
    ({ id }) => id === 'widthRange'
  );
  const prevValueInPageState = usePrevious(valueInPageState);

  const arrayValueInPageState = valueInPageState?.split('-') ?? [];
  const prevArrayValueInPageState = prevValueInPageState?.split('-') ?? [];

  const [minValueInPageState, maxValueInPageState] = arrayValueInPageState;
  const [
    prevMinValueInPageState,
    prevMaxValueInPageState,
  ] = prevArrayValueInPageState;

  const [minimum, setMinimum] = useState(minValueInPageState ?? '');
  const [maximum, setMaximum] = useState(maxValueInPageState ?? '');

  const minNum = parseInt(minimum, 10);
  const maxNum = parseInt(maximum, 10);

  const minError = useMemo(() => {
    if (minNum < minAllowed) {
      return `Minimum value cannot be less than ${minAllowed}`;
    }
    if (minNum > maxAllowed) {
      return `Minimum value cannot be more than ${maxAllowed}`;
    }
    if (minNum > maxNum) {
      return `Minimum value cannot be greater than ${maxNum}`;
    }

    return '';
  }, [minNum, maxNum, minAllowed, maxAllowed]);

  const maxError = useMemo(() => {
    if (maxNum < minAllowed) {
      return `Maximum value cannot be less than ${minAllowed}`;
    }
    if (maxNum > maxAllowed) {
      return `Maximum value cannot be more than ${maxAllowed}`;
    }
    if (maxNum < minNum) {
      return `Maximum value cannot be less than ${minNum}`;
    }

    return '';
  }, [minNum, maxNum, minAllowed, maxAllowed]);

  const debouncedMinError = useDebouncedValue(minError, 1000);
  const debouncedMaxError = useDebouncedValue(maxError, 1000);

  const widthRangeError = useMemo(
    () => ({
      min: debouncedMinError,
      max: debouncedMaxError,
    }),
    [debouncedMinError, debouncedMaxError]
  );

  useEffect(() => {
    setMinimum(latestValue => {
      if (
        prevMinValueInPageState &&
        !minValueInPageState &&
        !!latestValue &&
        latestValue === prevMinValueInPageState
      ) {
        return '';
      }

      return latestValue;
    });
  }, [minValueInPageState, prevMinValueInPageState]);

  useEffect(() => {
    setMaximum(latestValue => {
      if (
        prevMaxValueInPageState &&
        !maxValueInPageState &&
        !!latestValue &&
        latestValue === prevMaxValueInPageState
      ) {
        return '';
      }

      return latestValue;
    });
  }, [maxValueInPageState, prevMaxValueInPageState]);

  useEffect(() => {
    if (!minimum && !maximum) {
      setCustomFilter('widthRange', '');
      return;
    }
    setCustomFilter('widthRange', `${minimum ?? ''}-${maximum ?? ''}`);
  }, [maximum, minimum, setCustomFilter]);

  return (
    <div className="flex flex-col">
      {showTitle && <h3 className="text-lg font-bold">Width</h3>}
      <div className="flex flex-row items-center justify-between">
        <LabeledTextInput
          variant="range-filter"
          type="number"
          name="min"
          data-xc="min-width-filter-field"
          label="Min"
          placeholder="Min"
          id="min-width"
          className="first:mr-[5px]"
          onChange={event => {
            setMinimum(event?.target?.value);
          }}
          value={minimum}
          postfixText="in."
          noMargin
        />

        <LabeledTextInput
          variant="range-filter"
          type="number"
          name="max"
          data-xc="max-width-filter-field"
          label="Max"
          placeholder="Max"
          id="max-width"
          className="last:ml-[5px]"
          onChange={event => {
            setMaximum(event?.target?.value);
          }}
          value={maximum}
          postfixText="in."
          noMargin
        />
      </div>

      {(widthRangeError.max || widthRangeError.min) && (
        <ValidationError block>
          {widthRangeError.min || widthRangeError.max}
        </ValidationError>
      )}
    </div>
  );
};

ProductListingWidthRangeFilter.propTypes = {
  showTitle: PropTypes.bool,
};

export default ProductListingWidthRangeFilter;
