import React from 'react';
import useScreen from 'hooks/useScreen';
import QuickShipFilter from 'components/essentials/ConsumerFilters/components/QuickShipFilter';
import useProductListingPageContext from 'data-hooks/useProductListingPageContext';

const ProductListingQuickshipFilter = () => {
  const { filters, toggleBooleanFilter } = useProductListingPageContext();
  const quickshipFilter = filters.find(({ id }) => id === 'quickship');
  const { isMobile, isMobileOrTablet } = useScreen();

  if (!quickshipFilter) {
    return null;
  }

  return (
    <QuickShipFilter
      isChecked={quickshipFilter.isActive}
      isTablet={isMobileOrTablet && !isMobile}
      minHeight={false}
      onChange={() => {
        toggleBooleanFilter('quickship');
      }}
      withLocation
    />
  );
};

export default ProductListingQuickshipFilter;
