import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTimeout, useWindowScroll } from 'react-use';

import useIsBrowser from 'hooks/useIsBrowser';
import useElementDimensions from 'hooks/useElementDimensions';
import TextPlaceholder from 'components/consumer/TextPlaceholder';

const ProductListingPageName = ({
  loading = false,
  pageName,
  showPageNameWhileLoading = false,
}) => {
  const { y: currentScrollPosition = 0 } = useWindowScroll();
  const shrinkPageName = currentScrollPosition > 50;

  const titleRef = useRef(null);
  const { width: titleWidth } = useElementDimensions(titleRef);

  // Wait for initial layout to render before resizing
  const [shouldResize] = useTimeout(50);

  // Check for mounting so that this is only enabled on the client, to prevent
  // hydration mismatches
  const isBrowser = useIsBrowser();

  if (loading && !showPageNameWhileLoading) {
    return (
      <>
        <TextPlaceholder className="!w-[250px] !h-7" />{' '}
      </>
    );
  }

  // Due to a limitation of CSS (https://stackoverflow.com/questions/37406353/make-container-shrink-to-fit-child-elements-as-they-wrap/37413580#37413580)
  // we need to set the max-width of the title container to the width of the
  // title by using JavaScript.
  return (
    <div
      style={{
        maxWidth:
          isBrowser && shouldResize && titleWidth ? titleWidth + 5 : undefined,
      }}
    >
      <h1
        className={classNames(
          'origin-left transform transition-transform ease-in-out delay-150 text-xl font-bold md:text-2xl scale-100',
          {
            'md:!scale-90': isBrowser && shrinkPageName,
          }
        )}
        ref={titleRef}
      >
        {pageName}
      </h1>
    </div>
  );
};

ProductListingPageName.propTypes = {
  loading: PropTypes.bool,
  pageName: PropTypes.string,
};

export default ProductListingPageName;
