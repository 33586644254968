import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import TabletAndDesktopOnly from 'components/consumer/TabletAndDesktopOnly';

const DesktopProductListingSidebarContainer = ({ children, isOpen }) => (
  <TabletAndDesktopOnly>
    <div
      className={classNames(
        'sticky overflow-x-hidden transition-[all_0.22s_ease-in-out] max-h-full lg:max-h-[calc(100vh_-_3.75rem_-_53px)] top-[20px] lg:top-[calc(3.75rem_+_53px)] self-start',
        {
          'opacity-1 w-[140px] min-w-[140px] lg:w-[240px] lg:min-w-[240px]': isOpen,
          'opacity-0 w-0 min-w-0': !isOpen,
        }
      )}
    >
      <div className="pr-6 w-[140px] min-w-[140px] lg:w-[240px] lg:min-w-[240px]">
        {children}
      </div>
    </div>
  </TabletAndDesktopOnly>
);

DesktopProductListingSidebarContainer.propTypes = {
  children: PropTypes.node,
  isOpen: PropTypes.bool,
};

export default DesktopProductListingSidebarContainer;
