import { atom } from 'jotai';
import { useMemo } from 'react';

import usePDPContext from './context/usePDPContext';

// This returns an atom from the PDP context if it exists, otherwise it returns
// a default atom (e.g. if accessed outside of PDP).

// Note: It is critical that the default value has a stable identity to prevent
// unnecessary re-renders - it is recommended to use either a primitive value
// or a static variable that is defined outside of the hook that calls this.
const usePdpAtom = (atomName, defaultValue) => {
  const atomFromContext = usePDPContext().atoms?.[atomName];
  const defaultAtom = useMemo(() => atom(defaultValue), [defaultValue]);
  const pdpAtom = atomFromContext ?? defaultAtom;

  return pdpAtom;
};

export default usePdpAtom;
