import { useEffect, useState } from 'react';

const useElementDimensions = elementRef => {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const getDimensions = () => ({
      width: elementRef.current?.offsetWidth ?? 0,
      height: elementRef.current?.offsetHeight ?? 0,
    });

    const handleResize = () => {
      setDimensions(getDimensions());
    };

    if (elementRef.current) {
      setDimensions(getDimensions());
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [elementRef]);

  return dimensions;
};

export default useElementDimensions;
