import React from 'react';
import loadable from '@loadable/component';

import ClearAllFiltersButton from 'components/consumer/ClearAllFiltersButton';
import useProductListingPageContext from 'data-hooks/useProductListingPageContext';

const AnimateHeight = loadable(() => import('react-animate-height'));

const ProductListingClearAllFiltersButton = () => {
  const { activeFilterCount, resetFilters } = useProductListingPageContext();
  const shouldShowButton = !!activeFilterCount;

  // Button is wrapped in a div to prevent stretching of button when used
  // within flex container.
  return (
    <AnimateHeight
      duration={300}
      height={shouldShowButton ? 'auto' : 0}
      animateOpacity
    >
      <div className="md:pb-5">
        <ClearAllFiltersButton onClick={resetFilters}>
          Clear all
        </ClearAllFiltersButton>
      </div>
    </AnimateHeight>
  );
};

export default ProductListingClearAllFiltersButton;
